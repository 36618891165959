.join_community_container {
  padding: 2rem 0;
  margin-top: 8rem;
  background-color: #17191a;
}

.join_community_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  /* background-color: blueviolet; */
}

.join_community_text_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;
}

.join_community_text_btn h2 {
  width: 70%;
  line-height: 1.3076;
  font-weight: 700;
  font-size: 2.4rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #ecedee;
}

.btn {
  padding: 0.6rem 2.1rem;
  font-size: 1.1rem;
  font-weight: 500;
}

@media screen and (max-width: 924px) {
  .join_community_container {
    padding: 2rem 0;
    margin-top: 5rem;
    background-color: var(--color-main-bg-2);
  }
  .join_community_text_btn h2 {
    width: 90%;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 2.4rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #ecedee;
  }
}

@media screen and (max-width: 700px) {
  .join_community_text_btn h2 {
    width: 90%;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 2rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #ecedee;
  }
}

@media screen and (max-width: 451px) {
  .join_community_text_btn h2 {
    width: 90%;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 1.6rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #ecedee;
  }
  .btn {
    padding: 0.5rem 1.3rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.why_hybrid_cont {
  width: 100%;
  height: fit-content;
  padding: 50px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.why_hybrid_cont_sub {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-between;
}

.hybrid_cont_left {
  width: 50%;
  height: fit-content;
  align-self: center;
}

.hybrid_cont_left h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 27px;
}

.hybrid_cont_left div {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}

.hybrid_cont_left div span {
  font-size: 18px;
}

.why_img_cont {
  align-self: center;
}

.why_img_cont > img {
  /* width: 35rem; */
  height: 30rem;
}

@media screen and (max-width: 1024px) {
  .why_hybrid_cont {
    width: 100%;
    height: fit-content;
    padding: 80px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .why_hybrid_cont_sub {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
  }
  .hybrid_cont_left h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 20px;
    text-align: center;
  }
  .hybrid_cont_left {
    width: 100%;
    height: fit-content;
    align-self: center;
  }
}

@media screen and (max-width: 767px) {
  .hybrid_cont_left h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 27px;
  }
  .hybrid_cont_left div span {
    font-size: 17px;
  }
}

@media screen and (max-width: 570px) {
  .why_img_cont > img {
    width: 25rem;
    height: 20rem;
  }
}

@media screen and (max-width: 406px) {
  .why_img_cont > img {
    width: 100%;
    height: 18rem;
  }
}

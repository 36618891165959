.free_course_container {
  padding: 1.5rem 0;
  /* background-color: red; */
}

.free_course_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  margin-top: 10rem;
  /* background-color: blueviolet; */
}

.free_course {
  width: 100%;
  height: fit-content;
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  /* border: 1px solid #3f4345; */
  border-radius: 18px;
  background-color: var(--color-1);
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../../assets/images/free_cont_bg.png");
  background-size: cover; */
}

.free_course_text_cont {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  gap: 0.5rem;
  text-align: left;
}

.free_course_text_cont h2 {
  color: #ecedee;
  line-height: 1.3076;
  font-weight: 700;
  font-size: 2.4rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.free_course_text_cont p {
  color: var(--color-mid-white);
  font-weight: 400;
  font-size: 1rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  margin-bottom: 2.5rem;
}

.img_cont {
  width: 30%;
  height: 100%;
  position: relative;
  /* background-color: yellow; */
}

.img_cont img {
  position: absolute;
  top: -7.5rem;
  right: 1rem;
  height: 24rem;
  width: 24rem;
}

.free_analysis {
  width: 100%;
  height: fit-content;
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #3f4345;
  border-radius: 18px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../../../assets/images/free_cont_bg.png");
  background-size: cover;
}

.free_analysis_text_cont {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  text-align: left;
}

.free_analysis_text_cont h2 {
  color: #ecedee;
  line-height: 1.3076;
  font-weight: 700;
  font-size: 2rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.free_analysis_text_cont p {
  color: var(--color-mid-white);
  font-weight: 400;
  font-size: 0.9rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.free_analysis_btn_cont {
  width: fit-content;
  height: fit-content;
  align-self: center;
}

@media screen and (max-width: 1033px) {
  .free_course_text_cont h2 {
    color: #ecedee;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 2rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .free_course_text_cont p {
    color: var(--color-mid-white);
    font-weight: 400;
    font-size: 0.9rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
  .img_cont img {
    position: absolute;
    top: -6rem;
    right: -1rem;
    height: 22rem;
    width: 22rem;
  }
}

@media screen and (max-width: 941px) {
  .free_course_text_cont p {
    color: var(--color-mid-white);
    font-weight: 400;
    font-size: 0.88rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
  .free_analysis_text_cont p {
    color: var(--color-mid-white);
    font-weight: 400;
    font-size: 0.88rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

@media screen and (max-width: 924px) {
  .free_course_container_sub {
    max-width: 1248px;
    padding: 0.5rem 1.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    margin-top: 6rem;
    /* background-color: blueviolet; */
  }
  .free_course {
    width: 100%;
    height: fit-content;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    /* border: 1px solid #3f4345; */
    border-radius: 18px;
    background-color: var(--color-1);
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../../../../assets/images/free_cont_bg.png");
    background-size: cover; */
  }
  .free_course_text_cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    gap: 0.5rem;
    text-align: center;
  }
  .free_course_text_cont p {
    color: var(--color-mid-white);
    width: 80%;
    font-weight: 400;
    font-size: 0.9rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    margin-top: 0.5rem;
  }
  .img_cont {
    width: 100%;
    /* height: 25rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
  }
  .img_cont img {
    position: unset;
    height: 20rem;
    width: 20rem;
  }

  .free_analysis {
    width: 100%;
    height: fit-content;
    margin-top: 3rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    border: 1px solid #3f4345;
    border-radius: 18px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../../../assets/images/free_cont_bg.png");
    background-size: cover;
  }

  .free_analysis_text_cont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    text-align: center;
  }
  .free_analysis_text_cont p {
    color: var(--color-mid-white);
    font-weight: 400;
    font-size: 0.9rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 689px) {
  .free_course_text_cont p {
    color: var(--color-mid-white);
    width: 100%;
    font-weight: 400;
    font-size: 0.9rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 451px) {
  .free_course {
    width: 100%;
    height: fit-content;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    /* border: 1px solid #3f4345; */
    border-radius: 18px;
    background-color: var(--color-1);
  }
  .free_course_text_cont h2 {
    color: #ecedee;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 1.6rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
  .free_analysis {
    width: 100%;
    height: fit-content;
    margin-top: 3rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    border: 1px solid #3f4345;
    border-radius: 18px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../../../assets/images/free_cont_bg.png");
    background-size: cover;
  }
  .free_analysis_text_cont h2 {
    color: #ecedee;
    line-height: 1.3076;
    font-weight: 700;
    font-size: 1.6rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

@media screen and (max-width: 379px) {
  .img_cont img {
    position: unset;
    height: 100%;
    width: 100%;
  }
}

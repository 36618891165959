.main_carousel > div:first-of-type > div:first-of-type {
  bottom: -55px !important;
}

.main_carousel > div:first-of-type > div:first-of-type button {
  width: 4.625rem;
  height: 0.175rem;
}

.carousel_item {
  height: 26rem;
  width: 51rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #3f4345;
  border-radius: 20px;
  background-color: var(--color-mbl-card-bg-2);
}

.cross_btn {
  width: 100%;
  padding-right: 0.5rem;
  padding-top: 0.6rem;
  display: flex;
  justify-content: flex-end;
  /* background-color: yellow; */
}

.carousel_item_sub {
  height: 100%;
  width: 100%;
  padding: 2.2rem 2rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.carousel_item_left {
  height: 100%;
  width: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  /* border: 1px solid #3f4345;
  border-radius: 20px; */
  /* background-color: yellow; */
}

.carousel_item_left h2 {
  color: #ecedee;
  font-size: 2.4rem;
  font-weight: 700;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.carousel_item_left p {
  width: 90%;
  font-weight: 400;
  font-size: 1rem;
  color: var(--color-dark-white);
}

.numbers_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: red; */
}

.numbers_sub {
  height: fit-content;
  padding: 0rem 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background-color: #3f4345; */
}

.numbers_sub span {
  white-space: nowrap;
  color: var(--color-dark-white);
}

.carousel_item_right {
  height: 100%;
  width: 10rem;
  border: 1px solid #3f4345;
  border-radius: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/images/bgDashboard.jpg");
  background-size: cover;
  overflow: hidden;
}

.carousel_item_right_sub {
  width: 100%;
  height: 100%;
  padding: 1rem 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
}

.carousel_item_right_sub p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: var(--color-mid-white);
  margin-bottom: 0;
}

@media screen and (max-width: 924px) {
  .main_carousel {
    width: 100%;
    height: fit-content;
    /* background-color: aqua; */
  }
  .carousel_item {
    height: 26rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #3f4345;
    border-radius: 20px;
    background-color: var(--color-mbl-card-bg-2);
  }
  .carousel_item_right {
    height: 100%;
    min-width: 9rem;
    border: 1px solid #3f4345;
    border-radius: 20px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/images/bgDashboard.jpg");
    background-size: cover;
    overflow: hidden;
  }

  .carousel_item_right_sub {
    width: 100%;
    height: 100%;
    padding: 1rem 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
  }
}

@media screen and (max-width: 755px) {
  .carousel_item {
    height: 24.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #3f4345;
    border-radius: 20px;
    background-color: var(--color-mbl-card-bg-2);
  }
  .carousel_item_left h2 {
    color: #ecedee;
    font-size: 2.2rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

@media screen and (max-width: 724px) {
  .carousel_item {
    height: 24rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #3f4345;
    border-radius: 20px;
    background-color: var(--color-mbl-card-bg-2);
  }
  .carousel_item_left h2 {
    color: #ecedee;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 30px;
  }
}

@media screen and (max-width: 675px) {
  .carousel_item {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #3f4345;
    border-radius: 20px;
    background-color: var(--color-mbl-card-bg-2);
  }
  .carousel_item_sub {
    height: 29rem;
    width: 100%;
    padding: 2.2rem 2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2.2rem;
  }

  .carousel_item_left {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    /* border: 1px solid #3f4345;
    border-radius: 20px; */
    /* background-color: yellow; */
  }

  .carousel_item_left h2 {
    color: #ecedee;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .carousel_item_left p {
    width: 90%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    color: var(--color-dark-white);
  }

  .carousel_item_right {
    min-height: 5rem;
    min-width: 100%;
    border: 1px solid #3f4345;
    border-radius: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/images/bgDashboard.jpg");
    background-size: cover;
    overflow: hidden;
  }

  .carousel_item_right_sub {
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }
}

@media screen and (max-width: 592px) {
  .carousel_item_left h2 {
    color: #ecedee;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .carousel_item_left p {
    width: 90%;
    text-align: center;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--color-dark-white);
  }
  .carousel_item_sub {
    height: 29rem;
    width: 100%;
    padding: 2rem 1.2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }
  .numbers_sub h3 {
    font-size: 1.3rem;
    white-space: nowrap;
  }
  .numbers_sub span {
    font-size: 0.8rem;
    white-space: nowrap;
    color: var(--color-dark-white);
  }

  .carousel_item_right {
    max-height: 2rem;
    width: 100%;
    border: 1px solid #3f4345;
    border-radius: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/images/bgDashboard.jpg");
    background-size: cover;
    overflow: hidden;
  }
  .carousel_item_right_sub {
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .carousel_item_right_sub p {
    text-align: left;
  }
}

@media screen and (max-width: 448px) {
  .carousel_item_left h2 {
    color: #ecedee;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .carousel_item_left p {
    width: 90%;
    text-align: center;
    font-weight: 400;
    font-size: 0.85rem;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 407px) {
  .numbers_sub h3 {
    font-size: 1.3rem;
    white-space: nowrap;
  }
  .numbers_sub span {
    font-size: 0.7rem;
    font-weight: 600;
    white-space: nowrap;
    color: var(--color-dark-white);
  }
  .carousel_item_right_sub p {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    color: var(--color-mid-white);
    margin-bottom: 0;
    text-align: left;
  }
  .carousel_item_right {
    max-height: 1.5rem;
    width: 100%;
    border: 1px solid #3f4345;
    border-radius: 10px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../assets/images/bgDashboard.jpg");
    background-size: cover;
    overflow: hidden;
  }
  .carousel_item_right_sub {
    width: 100%;
    height: 100%;
    padding: 0.5rem 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}

@media screen and (max-width: 393px) {
  .carousel_item_sub {
    height: 29rem;
    width: 100%;
    padding: 2rem 1.2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 353px) {
  .carousel_item_left h2 {
    color: #ecedee;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

/* @media screen and (max-width: 924px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 3rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: -25px !important;
  }
} */

/* @media screen and (max-width: 500px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 2.5rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: -15px !important;
  }
}

@media screen and (max-width: 479px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 2.5rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: 10px !important;
  }
}

@media screen and (max-width: 447px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 2.5rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: 20px !important;
  }
}

@media screen and (max-width: 415px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 2rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: 40px !important;
  }
}

@media screen and (max-width: 386px) {
  .main_carousel > div:first-of-type > div:first-of-type button {
    width: 2rem;
    height: 0.1rem;
  }
  .main_carousel > div:first-of-type > div:first-of-type {
    bottom: 60px !important;
  }
} */

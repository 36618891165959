.course_detail_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  margin: auto;
  /* background-color: red; */
}

.course_detail_container_main_sub {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #fafafa;
}

.sec_one {
  width: 100%;
  height: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: #0030dc; */
}

.sec_one_text_cont {
  width: 80%;
  height: fit-content;
  padding-bottom: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: #0030dc; */
}

.sec_one_head_box {
  width: 27rem;
  height: fit-content;
  padding: 0.5rem;
  margin-top: 30px;
  margin-bottom: 25px;
  border-radius: 10px;
  text-align: center;
  background-color: var(--color-black);
}

.sec_one_text_cont h4 {
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0;
  text-align: center;
}

.sec_one_head_box h4 {
  font-family: "Poppins", Sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.4em;
  color: #fff;
}

.sec_one_text_cont h2 {
  font-size: 38px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
  text-align: center;
  line-height: 1.4em;
  margin-bottom: 30px;
  color: #000;
}

.one_img_why_cont {
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
  /* background-color: aqua; */
}

.one_img_cont {
  align-self: center;
}

.one_img_cont > img {
  /* width: 35rem; */
  height: 35rem;
}

.one_why_cont {
  display: flex;
  flex-direction: column;
}

.why_heading_cont {
  width: 100%;
  margin-bottom: 27px;
}

.one_why_cont h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
}

.one_why_cont_box {
  padding: 40px 30px;
  border-radius: 16px;
  margin-bottom: 40px;
  background-color: var(--color-black);
}

.one_why_cont_box ul {
  padding-left: 0rem;
  margin-bottom: 0rem;
}

.one_why_cont_box ul li {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 23px;
}

.one_why_cont_box ul li img {
  width: 1.2rem;
  height: 1.2rem;
}

.one_why_cont_box ul li span {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-white);
}

.one_btn_cont {
  width: 100%;
  height: fit-content;
}

.one_btn_cont button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0067de;
  border-radius: 15px 15px 15px 15px;
  border: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 22px 10px 22px 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.one_btn_cont button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

.one_btn_cont button div {
  position: absolute;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #fff;
}

.buy_now_cont {
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  padding: 0.7rem;
  /* border-radius: 8px; */
  background-color: var(--color-btn-text);
}

.buy_now_cont_sub {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.price_cont {
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.price_cont img {
  width: 40px;
  height: 40px;
}

.strike_price_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}

.strike_price_text {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0px;
}

.offer_price_text {
  color: #dddddd;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-decoration: line-through;
  line-height: 1.4em;
  margin-bottom: 0px;
  text-decoration: line-through;
}

.price_cont > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price_cont > div h2 {
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
}

.buy_now_btn {
  color: var(--color-white) !important;
  background-color: #0067de !important;
  position: relative;
  overflow: hidden;
}

.buy_now_btn::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

.buy_now_cont button {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0067de;
  border-radius: 15px 15px 15px 15px;
  border: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 10px 30px 10px 30px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.buy_now_cont button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(160deg) translate(-300%, -300%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(160deg) translate(300%, 300%);
    opacity: 0;
  }
}

@media screen and (max-width: 1073px) {
  .sec_one_text_cont {
    width: 90%;
    height: fit-content;
    padding-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0030dc; */
  }
  .one_img_why_cont {
    width: 95%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    /* background-color: aqua; */
  }
  .buy_now_cont_sub {
    width: 95%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .sec_one_text_cont {
    width: 90%;
    height: fit-content;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0030dc; */
  }
  .sec_one_text_cont {
    width: 99%;
    height: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0030dc; */
  }
  .sec_one_text_cont h2 {
    font-size: 33px;
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    text-align: center;
    line-height: 1.4em;
    margin-bottom: 30px;
  }
  .sec_one_text_cont h4 {
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 0;
    text-align: center;
  }
  .sec_one_head_box h4 {
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .one_img_why_cont {
    width: 99%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    /* background-color: aqua; */
  }
}

@media screen and (max-width: 900px) {
  .price_cont img {
    width: 30px;
    height: 30px;
  }

  .strike_price_text {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 0px;
  }

  .offer_price_text {
    color: #dddddd;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-decoration: line-through;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-decoration: line-through;
  }

  .price_cont > div h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .price_cont > div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  .buy_now_cont button {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 10px 25px 10px 25px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 800px) {
  .sec_one_text_cont {
    width: 100%;
    height: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: #0030dc; */
  }
}

@media screen and (max-width: 767px) {
  .sec_one_text_cont h2 {
    font-size: 27px;
    font-weight: 600;
    font-family: "Poppins", Sans-serif;
    text-align: center;
    line-height: 1.4em;
    margin-bottom: 25px;
  }
  .sec_one_head_box {
    width: 50%;
    height: fit-content;
    padding: 0.5rem;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    text-align: center;
    background-color: var(--color-main-bg);
  }

  .sec_one_text_cont h4 {
    width: 70%;
    font-family: "Poppins", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 0;
    text-align: center;
  }
  .sec_one_head_box h4 {
    width: 100%;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .one_why_cont h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-align: center;
  }
  .one_why_cont_box ul li span {
    font-family: "Poppins", Sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: var(--color-white);
  }
}

@media screen and (max-width: 650px) {
  .buy_now_cont_sub {
    width: 96%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .price_cont {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .price_cont img {
    width: 30px;
    height: 30px;
  }

  .strike_price_text {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 0px;
  }

  .offer_price_text {
    color: #dddddd;
    font-family: "Poppins", Sans-serif;
    font-size: 11px;
    font-weight: 600;
    text-decoration: line-through;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-decoration: line-through;
  }

  .price_cont > div h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .price_cont > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .buy_now_cont button {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 10px 22px 10px 22px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 570px) {
  .sec_one_head_box {
    width: 85%;
    height: fit-content;
    padding: 0.5rem;
    margin-bottom: 25px;
    border-radius: 10px;
    text-align: center;
    background-color: var(--color-main-bg);
  }
  .one_img_cont > img {
    width: 25rem;
    height: 30rem;
  }
}

@media screen and (max-width: 475px) {
  .buy_now_cont_sub {
    width: 99%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .price_cont {
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }

  .price_cont img {
    width: 25px;
    height: 25px;
    display: none;
  }

  .strike_price_text {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 0px;
  }

  .offer_price_text {
    color: #dddddd;
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-decoration: line-through;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-decoration: line-through;
  }

  .price_cont > div h2 {
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .price_cont > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .buy_now_cont button {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 10px 18px 10px 18px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 406px) {
  .sec_one_head_box {
    width: 90%;
    height: fit-content;
    padding: 0.5rem;
    margin-bottom: 25px;
    border-radius: 10px;
    text-align: center;
    background-color: var(--color-main-bg);
  }
  .one_img_cont > img {
    width: 100%;
    height: 30rem;
  }
}

@media screen and (max-width: 365px) {
  .sec_one_head_box {
    width: 90%;
    height: fit-content;
    padding: 0.5rem;
    margin-bottom: 25px;
    border-radius: 10px;
    text-align: center;
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 413px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 80px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

.benefits_cont {
  width: 100%;
  height: fit-content;
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.benefits_heading_cont {
  width: 100%;
  margin-bottom: 27px;
}

.benefits_heading_cont h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
}

.benefits_cont_sub {
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  margin: 0 auto;
}

.benefit_top {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* background-color: aqua; */
}

.benefit_box {
  width: 50%;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 40px 0px rgba(62, 62, 62, 0.1);
  position: relative;
}

.benefit_box span {
  font-size: 18px;
  margin-left: 2rem;
  color: var(--color-black);
}

.benefit_box div {
  position: absolute;
  left: 0px;
  top: 1.4rem;
  padding: 1px 10px 1px 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  background-color: #0067de;
}

.one_btn_cont {
  width: 25rem;
  height: fit-content;
  padding: 0px 30px;
  margin: 0 auto;
  margin-top: 40px;
}

.one_btn_cont button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0067de;
  border-radius: 15px 15px 15px 15px;
  border: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 22px 10px 22px 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.one_btn_cont button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

.one_btn_cont button div {
  position: absolute;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #fff;
}

@keyframes shine {
  0% {
    transform: rotate(160deg) translate(-300%, -300%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(160deg) translate(300%, 300%);
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {
  .one_btn_cont {
    width: 90%;
    height: fit-content;
    padding: 0px;
  }
}

@media screen and (max-width: 767px) {
  .benefits_heading_cont {
    width: 100%;
    margin-bottom: 35px;
  }

  .benefits_heading_cont h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
    margin-bottom: 0px;
    text-align: center;
  }
}

@media screen and (max-width: 741px) {
  .benefits_cont_sub {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    margin: 0 auto;
  }

  .benefit_top {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: aqua; */
  }

  .benefit_box {
    width: 100%;
    flex: 0;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding: 20px 30px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
  }
}

@media screen and (max-width: 601px) {
  .benefits_cont_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    margin: 0 auto;
  }
  .one_btn_cont {
    width: 100%;
    height: fit-content;
    padding: 0px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 570px) {
  .benefits_heading_cont {
    width: 100%;
    margin-bottom: 27px;
  }
}

@media screen and (max-width: 413px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 80px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

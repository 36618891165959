.top_image_container {
  width: 90%;
  height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  margin-top: 2rem;
  border: 1px solid var(--color-main-bg-2);
  border-radius: 15px;
  background-image: url(../../../assets/images/privacy_policy_image1.svg);
}

.top_image_container span:nth-child(1) {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  color: var(--color-white);
}

.top_image_container span:nth-child(2) {
  font-size: 0.9rem;
  margin-top: 1rem;
  max-width: 60%;
  text-align: center;
  color: var(--color-white);
}

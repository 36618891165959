.banner_container {
  padding: 8rem 0;
  padding-top: 7rem;
  margin-bottom: 1.3rem;
}

.banner_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: auto;
  position: relative;
  /* background-color: black; */
}

.banner_container_sub_slider {
  height: 27rem;
  width: 100%;
  padding: 0rem 5rem;
  padding-top: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background: rgba(57, 57, 57, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(195, 195, 195, 0.3);
  z-index: 2;
}

.bar_out {
  bottom: 3rem;
  left: 6.7%;
  height: 1rem;
  width: 8rem;
  margin-top: 20rem;
}

.bar_in {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_text {
  color: #369eff;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 600;
  left: 1.1rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.arrow_outer_one {
  position: absolute;
  right: 4.2rem;
  bottom: 6rem;
  width: 0.1rem;
  height: 10rem;
  display: flex;
  align-items: flex-end;
}

.arrow_in_one {
  width: 0.1rem;
  background-color: #369eff;
}

.typing_text_cont_one {
  position: absolute;
  white-space: nowrap;
  font-size: 1rem;
  bottom: 16.8rem;
  left: 3.5rem;
  color: #fff;
}

.bar_out_two {
  bottom: 8rem;
  left: 32%;
  height: 1rem;
  width: 8rem;
  margin-top: 10rem;
}

.bar_in_two {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_two_text {
  color: var(--color-white);
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 550;
  left: 3rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.bar_out_three {
  bottom: 13rem;
  left: 57.3%;
  height: 1rem;
  width: 8rem;
  margin-top: 0rem;
}

.bar_in_three {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_three_text {
  color: #ff802b;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 550;
  left: 1rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.bar_out_four {
  bottom: 18rem;
  left: 82.8%;
  height: 1rem;
  width: 8rem;
  margin-top: -10rem;
}

.bar_in_four {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_four_icon {
  left: 1.2rem;
  bottom: 2.5rem;
  position: absolute;
}

.arrow_outer_four {
  position: absolute;
  right: 7rem;
  bottom: 6rem;
  width: 17rem;
  height: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.arrow_in_four {
  height: 0.1rem;
  background-color: #3cb179;
}

.typing_text_cont_four {
  position: absolute;
  white-space: nowrap;
  font-size: 1rem;
  bottom: 6.8rem;
  right: 10rem;
  color: #fff;
}

.arrow_outer_four_one {
  position: absolute;
  right: 4rem;
  bottom: -8.5rem;
  width: 0.1rem;
  height: 7rem;
}

.arrow_in_four_one {
  width: 0.1rem;
  background-color: var(--color-dark-white);
}

.typing_text_cont_four_one {
  position: absolute;
  white-space: nowrap;
  bottom: -11rem;
  right: -1.4rem;
  color: #fff;
}

.arrow_outer_four_two {
  position: absolute;
  right: 4rem;
  bottom: -19rem;
  width: 0.1rem;
  height: 7rem;
}

.arrow_in_four_two {
  width: 0.1rem;
  background-color: var(--color-dark-white);
}

.what_we_do_btn {
  position: absolute;
  padding: 0.5rem 1.3rem;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  bottom: -23rem;
  right: -1rem;
  letter-spacing: -0.006rem;
  line-height: 1.57;
  border: 1px solid var(--color-btn);
  white-space: nowrap;
  color: var(--color-btn);
  background-color: transparent;
}

.array_text {
  position: absolute;
  width: 30rem;
  left: 4.8rem;
  bottom: -4.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
  /* background-color: red; */
}

.array_text_span {
  white-space: nowrap;
  bottom: -11rem;
  right: -1.4rem;
  color: #fff;
}

.consider_text {
  position: absolute;
  z-index: 1;
  left: 2rem;
  top: -2.7rem;
  color: var(--color-dark-white);
}

.ellipsis_design {
  position: absolute;
  z-index: 1;
  left: 3rem;
  bottom: -6.4rem;
}
.scroll_downs {
  bottom: -45%;
  left: 45%;
  width: 34px;
  height: 55px;
}
.mousey {
  width: 2px;
  padding: 10px 13px;
  height: 27px;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.modal_div {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .banner_container_sub_slider {
    height: 27rem;
    width: 100%;
    padding: 0rem 3rem;
    padding-top: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background: rgba(57, 57, 57, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
}

@media screen and (max-width: 924px) {
  .banner_container {
    padding: 8rem 0;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .banner_container_sub {
    max-width: 1248px;
    padding: 0.5rem 1.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12rem;
    margin: auto;
    position: relative;
    /* background-color: black; */
  }
  .banner_container_sub_slider {
    height: 26rem;
    width: 100%;
    padding: 0rem 3rem;
    padding-top: 1.2rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background: var(--color-mbl-card-bg-2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  .bar_out {
    bottom: 3rem;
    left: 6.7%;
    height: 1rem;
    width: 6rem;
    margin-top: 20rem;
  }
  .bar_out_text {
    color: #369eff;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    font-weight: 600;
    left: 0.7rem;
    bottom: 1.4rem;
    white-space: nowrap;
    position: absolute;
  }
  .arrow_outer_one {
    position: absolute;
    right: 3.2rem;
    bottom: 5rem;
    width: 0.1rem;
    height: 8rem;
    display: flex;
    align-items: flex-end;
  }

  .typing_text_cont_one {
    position: absolute;
    white-space: nowrap;
    font-size: 1rem;
    bottom: 13.6rem;
    left: 2.8rem;
    color: #fff;
  }
  .bar_out_two {
    bottom: 8rem;
    left: 32%;
    height: 1rem;
    width: 6rem;
    margin-top: 10rem;
  }
  .bar_out_two_text {
    color: var(--color-white);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    font-weight: 550;
    left: 2.2rem;
    bottom: 1.4rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_three {
    bottom: 13rem;
    left: 57.3%;
    height: 1rem;
    width: 6rem;
    margin-top: 0rem;
  }
  .bar_out_three_text {
    color: #ff802b;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    font-weight: 600;
    left: 0.5rem;
    bottom: 1.4rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_four {
    bottom: 18rem;
    left: 82.8%;
    height: 1rem;
    width: 6rem;
    margin-top: -10rem;
  }
  .bar_out_four_icon {
    left: 0.3rem;
    bottom: 2rem;
    position: absolute;
  }

  .arrow_outer_four {
    position: absolute;
    right: 6rem;
    bottom: 5rem;
    width: 15rem;
    height: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }

  .typing_text_cont_four {
    position: absolute;
    white-space: nowrap;
    font-size: 1rem;
    bottom: 5.8rem;
    right: 7rem;
    color: #fff;
  }
  .arrow_outer_four_one {
    position: absolute;
    right: 1.3rem;
    bottom: -8rem;
    width: 0.1rem;
    height: 6.5rem;
  }
  .typing_text_cont_four_one {
    position: absolute;
    white-space: nowrap;
    bottom: -10rem;
    right: 1.3rem;
    color: #fff;
  }
  .arrow_outer_four_two {
    position: absolute;
    right: 1.3rem;
    bottom: -18rem;
    width: 0.1rem;
    height: 7rem;
  }
  .what_we_do_btn {
    position: absolute;
    padding: 0.5rem 1.3rem;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    bottom: -21.5rem;
    right: 1.3rem;
    letter-spacing: -0.006rem;
    line-height: 1.57;
    border: 1px solid var(--color-btn);
    white-space: nowrap;
    color: var(--color-btn);
    background-color: var(--color-main-bg-2);
  }
  .consider_text {
    position: absolute;
    z-index: 1;
    left: 2rem;
    top: -2.5rem;
    color: var(--color-dark-white);
  }
  .ellipsis_design {
    position: absolute;
    z-index: 1;
    left: 3rem;
    bottom: 39rem;
  }

  .slider_cont {
    width: 100%;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }
}

@media screen and (max-width: 705px) {
  .banner_container {
    padding: 8rem 0;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .banner_container_sub_slider {
    height: 23.5rem;
    width: 100%;
    padding: 0rem 2rem;
    padding-top: 0rem;
    padding-bottom: 0.5rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background: var(--color-mbl-card-bg-2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  .bar_out {
    bottom: 3rem;
    left: 6.7%;
    height: 1rem;
    width: 5rem;
    margin-top: 20rem;
  }
  .bar_out_text {
    color: #369eff;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.5rem;
    font-weight: 600;
    left: 0.5rem;
    bottom: 1.2rem;
    white-space: nowrap;
    position: absolute;
  }
  .arrow_outer_one {
    position: absolute;
    right: 2.7rem;
    bottom: 4.2rem;
    width: 0.1rem;
    height: 8rem;
    display: flex;
    align-items: flex-end;
  }

  .typing_text_cont_one {
    position: absolute;
    white-space: nowrap;
    font-size: 0.9rem;
    bottom: 13rem;
    left: 2.3rem;
    color: #fff;
  }
  .bar_out_two {
    bottom: 8rem;
    left: 32%;
    height: 1rem;
    width: 5rem;
    margin-top: 10rem;
  }
  .bar_out_two_text {
    color: var(--color-white);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.6rem;
    font-weight: 550;
    left: 1.7rem;
    bottom: 1.2rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_three {
    bottom: 13rem;
    left: 57.3%;
    height: 1rem;
    width: 5rem;
    margin-top: 0rem;
  }
  .bar_out_three_text {
    color: #ff802b;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.5rem;
    font-weight: 600;
    left: 0.5rem;
    bottom: 1.2rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_four {
    bottom: 18rem;
    left: 82.8%;
    height: 1rem;
    width: 5rem;
    margin-top: -10rem;
  }
  .bar_out_four_icon {
    left: 1.1rem;
    bottom: 1.8rem;
    position: absolute;
  }

  .arrow_outer_four {
    position: absolute;
    right: 4.5rem;
    bottom: 3.5rem;
    width: 13.5rem;
    height: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }

  .typing_text_cont_four {
    position: absolute;
    white-space: nowrap;
    font-size: 0.9rem;
    bottom: 4rem;
    right: 5rem;
    color: #fff;
  }
  .arrow_outer_four_one {
    position: absolute;
    right: 1rem;
    bottom: -8rem;
    width: 0.1rem;
    height: 6.5rem;
  }
  .typing_text_cont_four_one {
    position: absolute;
    white-space: nowrap;
    font-size: 0.9rem;
    bottom: -10rem;
    right: 1rem;
    color: #fff;
  }
  .arrow_outer_four_two {
    position: absolute;
    right: 1rem;
    bottom: -18rem;
    width: 0.1rem;
    height: 7rem;
  }
  .what_we_do_btn {
    position: absolute;
    padding: 0.5rem 1.3rem;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    bottom: -21.5rem;
    right: -1.3rem;
    letter-spacing: -0.006rem;
    line-height: 1.57;
    border: 1px solid var(--color-btn);
    white-space: nowrap;
    color: var(--color-btn);
    background-color: var(--color-main-bg-2);
  }
  .consider_text {
    position: absolute;
    z-index: 1;
    left: 2rem;
    top: -2.25rem;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 581px) {
  .banner_container {
    padding: 8rem 0;
    padding-top: 5rem;
    padding-bottom: 7rem;
  }
  .banner_container_sub_slider {
    height: 22rem;
    width: 100%;
    padding: 0rem 1rem;
    padding-top: 0rem;
    padding-bottom: 2.4rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background: var(--color-mbl-card-bg-2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  .bar_out {
    bottom: 3rem;
    left: 6.7%;
    height: 0.8rem;
    width: 4rem;
    margin-top: 20rem;
  }
  .bar_out_text {
    color: #369eff;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.15rem;
    font-weight: 600;
    left: 0.5rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .arrow_outer_one {
    position: absolute;
    right: 2.2rem;
    bottom: 3.3rem;
    width: 0.1rem;
    height: 6.5rem;
    display: flex;
    align-items: flex-end;
  }

  .typing_text_cont_one {
    position: absolute;
    white-space: nowrap;
    font-size: 0.85rem;
    bottom: 10.3rem;
    left: 1.7rem;
    color: #fff;
  }
  .bar_out_two {
    bottom: 8rem;
    left: 32%;
    height: 0.8rem;
    width: 4rem;
    margin-top: 10rem;
  }
  .bar_out_two_text {
    color: var(--color-white);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.35rem;
    font-weight: 550;
    left: 1.4rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_three {
    bottom: 13rem;
    left: 57.3%;
    height: 0.8rem;
    width: 4rem;
    margin-top: 1rem;
  }
  .bar_out_three_text {
    color: #ff802b;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.15rem;
    font-weight: 600;
    left: 0.5rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_four {
    bottom: 18rem;
    left: 82.8%;
    height: 0.8rem;
    width: 4rem;
    margin-top: -8rem;
  }
  .bar_out_four_icon {
    left: 0.8rem;
    bottom: 1.5rem;
    position: absolute;
  }

  .arrow_outer_four {
    position: absolute;
    right: 3.5rem;
    bottom: 2.8rem;
    width: 12.8rem;
    height: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }

  .typing_text_cont_four {
    position: absolute;
    white-space: nowrap;
    font-size: 0.85rem;
    bottom: 3.2rem;
    right: 4.2rem;
    color: #fff;
  }
  .arrow_outer_four_one {
    position: absolute;
    right: 1rem;
    bottom: -9rem;
    width: 0.1rem;
    height: 8rem;
  }
  .typing_text_cont_four_one {
    position: absolute;
    white-space: nowrap;
    font-size: 0.85rem;
    bottom: -10.5rem;
    right: 1rem;
    color: #fff;
  }
  .arrow_outer_four_two {
    position: absolute;
    right: 1rem;
    bottom: -18.8rem;
    width: 0.1rem;
    height: 8rem;
  }
  .what_we_do_btn {
    position: absolute;
    padding: 0.5rem 1.3rem;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    bottom: -22rem;
    right: -0.8rem;
    letter-spacing: -0.006rem;
    line-height: 1.57;
    border: 1px solid var(--color-btn);
    white-space: nowrap;
    color: var(--color-btn);
    background-color: var(--color-main-bg-2);
  }
  .consider_text {
    position: absolute;
    z-index: 1;
    left: 2rem;
    top: -2.1rem;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 485px) {
  .banner_container {
    padding: 8rem 0;
    padding-top: 5rem;
    padding-bottom: 7rem;
    /* background-color: red; */
  }
  .banner_container_sub_slider {
    height: 22rem;
    width: 100%;
    padding: 0rem 1rem;
    padding-top: 1rem;
    padding-bottom: 2.4rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background: var(--color-mbl-card-bg-2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(67, 67, 67, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 2;
  }
  .bar_out {
    bottom: 3rem;
    left: 6.7%;
    height: 0.8rem;
    width: 3.5rem;
    margin-top: 20rem;
  }
  .bar_out_text {
    color: #369eff;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 600;
    left: 0.4rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .arrow_outer_one {
    position: absolute;
    right: 2rem;
    bottom: 3.3rem;
    width: 0.1rem;
    height: 6.5rem;
    display: flex;
    align-items: flex-end;
  }

  .typing_text_cont_one {
    width: 4rem;
    position: absolute;
    font-size: 0.8rem;
    bottom: 10.3rem;
    left: -0.4rem;
    text-align: center;
    color: #fff;
  }

  .typing_text_cont_one span {
    display: block;
    overflow: hidden;
    white-space: wrap !important;
  }

  .bar_out_two {
    bottom: 8rem;
    left: 32%;
    height: 0.8rem;
    width: 3.5rem;
    margin-top: 10rem;
  }

  .bar_out_two_text {
    color: var(--color-white);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.2rem;
    font-weight: 600;
    left: 1.2rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_three {
    bottom: 13rem;
    left: 57.3%;
    height: 0.8rem;
    width: 3.5rem;
    margin-top: 1rem;
  }
  .bar_out_three_text {
    color: #ff802b;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 600;
    left: 0.4rem;
    bottom: 0.9rem;
    white-space: nowrap;
    position: absolute;
  }
  .bar_out_four {
    bottom: 18rem;
    left: 82.8%;
    height: 0.8rem;
    width: 3.5rem;
    margin-top: -8rem;
  }
  .bar_out_four_icon {
    left: 0.6rem;
    bottom: 1.5rem;
    position: absolute;
  }

  .arrow_outer_four {
    position: absolute;
    right: 3.2rem;
    bottom: 2.8rem;
    width: 12.1rem;
    height: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }

  .typing_text_cont_four {
    position: absolute;
    white-space: nowrap;
    font-size: 0.8rem;
    bottom: 3.3rem;
    right: 4.2rem;
    color: #fff;
  }
  .arrow_outer_four_one {
    position: absolute;
    right: 0.5rem;
    bottom: -8rem;
    width: 0.1rem;
    height: 7.2rem;
  }
  .typing_text_cont_four_one {
    width: 5rem;
    position: absolute;
    white-space: nowrap;
    font-size: 0.8rem;
    bottom: -10.8rem;
    right: 0.4rem;
    color: #fff;
  }

  .typing_text_cont_four_one span {
    display: block;
    text-align: right;
    overflow: hidden;
    white-space: wrap !important;
  }

  .arrow_outer_four_two {
    position: absolute;
    right: 0.5rem;
    bottom: -18.8rem;
    width: 0.1rem;
    height: 7.5rem;
  }
  .what_we_do_btn {
    position: absolute;
    padding: 0.5rem 1.3rem;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    bottom: -22rem;
    right: -0.8rem;
    letter-spacing: -0.006rem;
    line-height: 1.57;
    border: 1px solid var(--color-btn);
    white-space: nowrap;
    color: var(--color-btn);
    background-color: var(--color-main-bg-2);
  }
  .consider_text {
    position: absolute;
    z-index: 1;
    left: 2rem;
    top: -2.3rem;
    color: var(--color-dark-white);
  }
}

/* add padding top - 7rem on main container */
/* 
.bar_out {
  bottom: 2rem;
  left: 2rem;
  height: 1rem;
  width: 8rem;
  position: absolute;
}

.bar_in {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_text {
  color: #369eff;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 600;
  left: 1.5rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.arrow_outer_one {
  position: absolute;
  right: 4.2rem;
  bottom: 6rem;
  width: 0.1rem;
  height: 10rem;
  display: flex;
  align-items: flex-end;
}

.arrow_in_one {
  width: 0.1rem;
  background-color: #369eff;
}

.typing_text_cont_one {
  position: absolute;
  white-space: nowrap;
  bottom: 16.8rem;
  left: 3.5rem;
  color: #fff;
}

.bar_out_two {
  bottom: 9rem;
  left: 23rem;
  height: 1rem;
  width: 8rem;
  position: absolute;
}

.bar_in_two {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_two_text {
  color: var(--color-white);
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 550;
  left: 2.8rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.bar_out_three {
  bottom: 16rem;
  left: 44rem;
  height: 1rem;
  width: 8rem;
  position: absolute;
}

.bar_in_three {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_three_text {
  color: #ff802b;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.2rem;
  font-weight: 550;
  left: 2rem;
  bottom: 2rem;
  white-space: nowrap;
  position: absolute;
}

.bar_out_four {
  bottom: 23rem;
  left: 65rem;
  height: 1rem;
  width: 8rem;
  position: absolute;
}

.bar_in_four {
  height: 100%;
  width: 40%;
  position: relative;
  background-color: var(--color-border);
}

.bar_out_four_icon {
  left: 1.2rem;
  bottom: 2.5rem;
  position: absolute;
}

.typing_text_cont_four {
  position: absolute;
  white-space: nowrap;
  bottom: 6.8rem;
  right: 10rem;
  color: #fff;
}

.arrow_outer_four {
  position: absolute;
  right: 7rem;
  bottom: 6rem;
  width: 17rem;
  height: 0.1rem;
  background-color: #3cb179;
}

.arrow_in_four {
  height: 0.1rem;
  background-color: var(--color-main-bg-2);
}

.arrow_outer_four_one {
  position: absolute;
  right: 4rem;
  bottom: -18.5rem;
  width: 0.1rem;
  height: 17rem;
}

.arrow_in_four_one {
  width: 0.1rem;
  background-color: var(--color-dark-white);
}

.typing_text_cont_four_one {
  position: absolute;
  white-space: nowrap;
  bottom: -21rem;
  right: 4rem;
  color: #fff;
} */

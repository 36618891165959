.consistency_container {
  padding: 6.5rem 0;
  padding-bottom: 2rem;
}

.consistency_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  /* background-color: blueviolet; */
}

.tab_cont {
  width: 100%;
  height: fit-content;
  padding: 0.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background-color: var(--color-transparent-bg);
}

.tab {
  text-align: center;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 500;
  font-family: Manrope, sans-serif;
  line-height: 25px;
  border-radius: 16px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.15s ease-in-out;
}

.content_cont {
  width: 100%;
}

.content_cont h2 {
  text-align: center;
  padding: 3.25rem 0.8rem;
  padding-bottom: 1rem;
  letter-spacing: -0.02em;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 65px;
  color: var(--color-dark-white);
}

.content_cont_heading {
  padding: 3.25rem 0;
  text-align: center;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.5rem;
  letter-spacing: -0.025em !important;
  line-height: 49px !important;
  color: var(--color-dark-white);
}

.text_img_cont {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: aqua; */
}

.text_main_cont {
  width: 45%;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  /* background-color: blanchedalmond; */
}

.text_cont {
  padding: 1rem 2.5rem;
  border-radius: 8px;
  background-color: var(--color-transparent-bg-2);
}

.text_cont h3 {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.5rem;
  letter-spacing: -0.025em !important;
  line-height: 39px !important;
  color: var(--color-white);
  margin-bottom: 0.8rem;
}

.text_cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--color-dark-white);
}

.img_cont {
  width: 36rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 6rem;
  overflow: hidden;
  /* background-color: yellow; */
}

.img_cont img {
  width: 38rem;
  height: 38rem;
}

@media screen and (max-width: 1112px) {
  .img_cont {
    width: 30rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 3rem;
    overflow: hidden;
    /* background-color: yellow; */
  }

  .img_cont img {
    width: 31.5rem;
    height: 31.5rem;
  }
}

@media screen and (max-width: 925px) {
  .consistency_container_sub {
    max-width: 1248px;
    padding: 0.5rem 1.5rem;
    padding-bottom: 4rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    /* background-color: blueviolet; */
  }
  .consistency_container {
    padding: 3rem 0;
    padding-bottom: 1rem;
  }
  .text_img_cont {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    /* background-color: aqua; */
  }

  .content_cont h2 {
    text-align: center;
    padding: 2.3rem 0.8rem;
    letter-spacing: -0.02em;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2rem;
    font-weight: 700;
    line-height: 60px;
    color: var(--color-dark-white);
  }

  .text_main_cont {
    width: 100%;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    /* background-color: blanchedalmond; */
  }

  .img_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-left: 0rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 571px) {
  .content_cont h2 {
    text-align: center;
    padding: 1.8rem 0.8rem;
    letter-spacing: -0.02em;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 50px;
    color: var(--color-dark-white);
  }
  .text_cont {
    padding: 1rem 2rem;
    border-radius: 8px;
    background-color: var(--color-transparent-bg-2);
  }
  .text_cont h3 {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.2rem;
    letter-spacing: -0.025em !important;
    line-height: 39px !important;
    color: var(--color-white);
    margin-bottom: 0.8rem;
  }

  .text_cont p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 30px;
    color: var(--color-dark-white);
  }
  .img_cont img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 491px) {
  .text_img_cont {
    width: 95%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    /* background-color: aqua; */
  }
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: blink 0.7s infinite alternate; /* Animation to make it blink */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

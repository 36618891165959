.modal_div {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.modal_div img {
  width: 6rem;
  height: 6rem;
}

.message {
  width: 100%;
  gap: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: var(--color-dark-white);
}

.footer_container {
  padding: 1.5rem 0;
  margin-top: 4rem;
  border-top: 1px solid;
}

.footer_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  /* background-color: blueviolet; */
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.footer_top {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: aqua; */
}

.footer_top_sub1 {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  /* background-color: green; */
}

.name_address {
  width: fit-content;
  padding: 0.3rem;
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  /* background-color: red; */
}

.google_play img {
  width: 9rem;
  height: 3.5rem;
  margin-top: 2rem;
  margin-left: 0rem;
}

.logo_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: -1rem;
  /* background-color: black; */
}

.logo_cont img {
  width: 2.3rem;
  height: 2.3rem;
}

.logo_cont h2 {
  letter-spacing: -0.02em;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 65px;
  margin-top: 1.2rem;
  color: #ecedee;
}

.address_cont {
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.address_cont p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #9ba1a6;
  /* background-color: tan; */
}

.legals {
  width: fit-content;
  padding: 0.3rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.legals span {
  font-size: 1rem;
  font-weight: 500;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #ecedee;
  margin-bottom: 0.8rem;
}

.legals p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #9ba1a6;
  cursor: pointer;
}

.social_media {
  width: fit-content;
  padding: 0.3rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.icons {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  background-color: var(--color-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.mail_mbl_cont {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1.8rem;
}

.mail_mbl {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.mail_mbl a {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
}

.footer_bottom {
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: yellow; */
}

.footer_bottom p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: var(--color-white);
  white-space: nowrap;
}

@media screen and (max-width: 330px) {
  .footer_bottom p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 0.9rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: var(--color-white);
    white-space: nowrap;
  }
}

.button {
  padding: 0.5rem 1.3rem;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.006rem;
  line-height: 1.57;
  border: none;
  white-space: nowrap;
  color: var(--color-btn-text);
  background-color: var(--color-btn);
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto&family=Source+Sans+Pro:wght@300&display=swap");

* {
  box-sizing: border-box;
}

body {
  --color-grey-50: hsl(265, 55%, 96%);
  --color-grey-100: hsl(265, 19%, 88%);
  --color-grey-200: hsl(265, 7%, 70%);
  --color-grey-300: hsl(265, 6%, 66%);
  --color-grey-400: hsl(265, 4%, 57%);
  --color-grey-500: hsl(265, 3%, 53%);
  --color-grey-600: hsl(263, 4%, 42%);
  --color-grey-700: hsl(265, 4%, 31%);
  --color-grey-800: hsl(276, 5%, 20%);
  --color-grey-900: hsl(280, 5%, 13%);

  /* --color-primary-50: #c8b3ce;
  --color-primary-100: #a07aaa;
  --color-primary-200: #884c97;
  --color-primary-300: #843897;
  --color-primary-400: #732392;
  --color-primary-500: #5a097a;
  --color-primary-600: #480264;
  --color-primary-700: #3d0264; */

  --color-main-bg: #151718;
  --color-main-bg-2: #0c0d0e;
  --color-transparent-bg: rgba(30, 30, 30, 0.7);
  --color-transparent-bg-2: rgba(30, 30, 30, 1);
  --color-1: #072d30;
  --color-2: #0c6e71;
  --color-btn: #0c969a;
  --color-btn-hover: #31b5b9;
  --color-3: #6ca7bd;
  --color-4: #284d5f;
  --color-white: #fff;
  --color-black: #000;
  --color-mid-white: #c9c8c7;
  --color-dark-white: #9ba1a6;
  --color-mbl-card-bg: #121415;
  --color-mbl-card-bg-2: #181a1b;
  --color-btn-text: #151718;
  --color-border: #313538;
  --color-input-border: #3f4345;
  --color-input-outline: #0954a5;

  --color-success-100: #a2f0bc;
  --color-success-500: #12bd4b;

  --color-error-100: #f1acc9;
  --color-error-500: #a10c4a;

  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-8: 2rem;
  --size-16: 4rem;
  --size-20: 5rem;
  --size-40: 10rem;

  margin: 0;
  height: 100%;
  background-color: var(--color-main-bg) !important;
  color: #252525;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
  font-family: "Oswald", "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

button {
  font: inherit;
  cursor: pointer;
}

.carousel-indicators {
  bottom: -65px;
}

/* For Webkit based browsers */
::-webkit-scrollbar {
  height: 3px;
  width: 4px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: none; /* transparent track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(265, 4%, 31%); /* color of the handle */
}

.banner_container {
  padding: 8rem 0;
}

.banner_container_sub {
  max-width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin: auto;
  /* background-color: black; */
}

.banner_container_sub_content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.why_container {
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #291415;
  border: 1px solid #671e22;
  border-radius: 50px;
  margin-bottom: var(--size-8);
}

.why_text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: var(--size-3);
  color: #f2555a;
}

.description_container {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.description_text {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.5rem;
  letter-spacing: -0.025em !important;
  line-height: 49px !important;
  color: var(--color-white);
}

.description_text_altered {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: var(--size-8);
  letter-spacing: -0.025em !important;
  line-height: 49px !important;
  padding: 0.375rem 0.5rem;
  margin: 0 0.5rem;
  color: #369eff;
  background-color: #10243e;
}

.small_desciption_text {
  max-width: 29.75rem;
  margin-bottom: 3.5rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem !important;
  line-height: 1.5 !important;
  color: var(--color-dark-white);
}

.banner_btn_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.banner_btn_container_signup {
  padding: 1rem 1.7rem !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
}

.banner_btn_container_view_analyses {
  padding: 1rem 1.7rem !important;
  background-color: transparent !important;
  border: 1px solid var(--color-btn) !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  color: var(--color-white) !important;
}

.banner_container_sub_slider {
  height: 27rem;
  width: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

@media screen and (max-width: 1300px) {
  .banner_container_sub {
    max-width: 1248px;
    height: fit-content;
    margin: auto;
  }
}

@media screen and (max-width: 1099px) {
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: var(--size-8);
    letter-spacing: -0.025em !important;
    line-height: 49px !important;
    color: var(--color-white);
  }
}
@media screen and (max-width: 974px) {
  .banner_container_sub_slider {
    height: 27rem;
    width: 26rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 924px) {
  .banner_container {
    padding: 3rem 0;
  }
  .banner_container_sub {
    max-width: 100%;
    padding: 0.5rem 1.5rem;
    height: fit-content;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    /* background-color: black; */
  }
  .banner_container_sub_content {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .why_text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: var(--size-4);
    color: #f2555a;
  }
  .description_container {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 3.5rem;
  }
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 3.5rem;
    letter-spacing: -0.025em !important;
    line-height: 67px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 1rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 3rem;
    letter-spacing: -0.025em !important;
    line-height: 49px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
  .small_desciption_text {
    max-width: 80%;
    margin-bottom: 3.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.4rem !important;
    line-height: 1.5 !important;
    text-align: center;
    color: var(--color-dark-white);
  }
  .banner_btn_container {
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    gap: 2rem;
  }
  .banner_container_sub_slider {
    height: 38rem;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 758px) {
  .why_text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 1rem;
    color: #f2555a;
  }
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 3rem;
    letter-spacing: -0.025em !important;
    line-height: 57px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.5rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2.5rem;
    letter-spacing: -0.025em !important;
    line-height: 39px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
}

@media screen and (max-width: 670px) {
  .description_container {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 3rem;
  }
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2.8rem;
    letter-spacing: -0.025em !important;
    line-height: 57px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.2rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2.2rem;
    letter-spacing: -0.025em !important;
    line-height: 39px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
}

@media screen and (max-width: 598px) {
  .why_text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 0.8rem;
    color: #f2555a;
  }
  .description_container {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
  }
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2.5rem;
    letter-spacing: -0.025em !important;
    line-height: 50px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.2rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
  .small_desciption_text {
    max-width: 80%;
    margin-bottom: 3.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.3rem !important;
    line-height: 1.3 !important;
    text-align: center;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 542px) {
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 2.2rem;
    letter-spacing: -0.025em !important;
    line-height: 50px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.1rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
}

@media screen and (max-width: 481px) {
  .why_text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 0.7rem;
    color: #f2555a;
  }
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.8rem;
    letter-spacing: -0.025em !important;
    line-height: 40px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.1rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.6rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
  .small_desciption_text {
    max-width: 85%;
    margin-bottom: 3.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.1rem !important;
    line-height: 1.3 !important;
    text-align: center;
    color: var(--color-dark-white);
  }
  .banner_btn_container {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .banner_btn_container_signup {
    padding: 0.7rem 1.7rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  .banner_btn_container_view_analyses {
    padding: 0.7rem 1.7rem !important;
    background-color: transparent !important;
    border: 1px solid var(--color-btn) !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: var(--color-white) !important;
  }
  .banner_container_sub_slider {
    height: 30rem;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 436px) {
  .banner_container_sub_slider {
    height: 28rem;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 407px) {
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.6rem;
    letter-spacing: -0.025em !important;
    line-height: 38px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.1rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.4rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
  .small_desciption_text {
    max-width: 80%;
    margin-bottom: 3.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.1rem !important;
    line-height: 1.3 !important;
    text-align: center;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 400px) {
  .banner_container_sub_slider {
    height: 25rem;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 381px) {
  .banner_container_sub_slider {
    height: 22rem;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 364px) {
  .description_text {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.5rem;
    letter-spacing: -0.025em !important;
    line-height: 33px !important;
    color: var(--color-white);
    margin-bottom: 1rem;
  }
  .altered_text_container {
    margin-top: 0.1rem;
  }
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.5rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
  .small_desciption_text {
    max-width: 80%;
    margin-bottom: 3.5rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem !important;
    line-height: 1.3 !important;
    text-align: center;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 347px) {
  .description_text_altered {
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.5rem;
    letter-spacing: -0.025em !important;
    line-height: 29px !important;
    padding: 0.375rem 0.5rem;
    margin: 0 0.5rem;
    color: #369eff;
    background-color: #10243e;
  }
}

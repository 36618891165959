.course_detail_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: auto;
  /* background-color: red; */
}

.course_detail_container_main_sub {
  width: 98%;
  height: 40rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  border: 1px solid var(--color-input-border);
  border-radius: 10px;
  padding: 1.3rem 1.3rem;
  overflow: auto;
}

.course_detail_container_main_sub_reg_det {
  width: 65%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none;
  /* background-color: #0030dc; */
}

.course_detail_container_main_sub_reg_det::-webkit-scrollbar {
  width: 0;
}

.about_course_cont {
  width: 100%;
  height: fit-content;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-top: 1.2rem;
  /* background-color: aquamarine; */
}

.about_course_cont_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-3);
}

.course_detail_header {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-mid-white);
}

.login_container {
  width: 100%;
  padding: 1.5rem;
  margin-top: 0.6rem;
  background-color: var(--color-main-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading_text {
  width: 50%;
  align-self: center;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: var(--size-6);
  font-weight: 500;
  text-align: left;
  color: var(--color-white);
}

.form_column {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  /* background-color: antiquewhite; */
}

.form_group {
  margin-bottom: 20px;
}

.form_column .form_group {
  width: 50%;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: var(--size-4);
  color: var(--color-white);
}

.input_field {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color-white);
  background-color: transparent;
}

.input_field:focus {
  outline-color: var(--color-btn);
  outline-width: 1.5px;
  outline-style: solid;
}

.submit_button {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.modal_div {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.already_acc_text {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--color-grey-600);
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.already_acc_text a {
  font-weight: 500;
  text-decoration: none;
  color: var(--color-btn);
}

.forgot_pw_text {
  width: 49%;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-grey-600);
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.you_pay_cont {
  width: 100%;
  height: fit-content;
  padding: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.you_pay_cont_sub {
  width: 100%;
  height: fit-content;
  margin-top: 0.6rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-self: center;
  flex-direction: row;
  border: 1px solid var(--color-btn);
  gap: 1rem;
  border-radius: 8px;
}

.you_pay_cont_sub input {
  cursor: pointer;
  accent-color: var(--color-btn);
}

.you_pay_cont_sub > span {
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.03rem;
  color: var(--color-mid-white);
}

.you_pay_cont_sub_price_cont {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: 0.8rem;
  margin-left: auto;
}

.redirect_cont {
  width: 100%;
  height: fit-content;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  background-color: var(--color-main-bg);
}

.redirect_cont img {
  width: 12rem;
  height: 12rem;
}

.redirect_cont span {
  width: 70%;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 0.03rem;
  color: var(--color-mid-white);
}

.pay_now_btn_cont {
  width: 98%;
  align-self: center;
}

.course_detail_container_main_sub_details {
  flex: 1;
  height: fit-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none;
}

.course_detail_container_main_sub_details::-webkit-scrollbar {
  width: 0;
}

.course_detail_container_main_sub_details_sub {
  flex: 1;
  padding: 1rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  border-radius: 8px;
  background-color: var(--color-main-bg);
  -ms-overflow-style: none;
}

.course_detail_container_main_sub_details_sub::-webkit-scrollbar {
  width: 0;
}

.summary_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.about_course_cont_content {
  width: 100%;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 0.9rem;
  color: var(--color-mid-white);
  overflow: auto;
}

.buy_now_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-left: auto;
}

.price_cont_text {
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.price_cont_text > span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-white);
}

.price_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.price_cont span {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-btn);
}

.buy_now_btn {
  width: 100%;
  color: var(--color-btn-text) !important;
  background-color: var(--color-btn) !important;
  position: relative;
  overflow: hidden;
}

.buy_now_btn::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(160deg) translate(-300%, -300%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(160deg) translate(300%, 300%);
    opacity: 0;
  }
}

.modal_div {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

@media screen and (max-width: 925px) {
  .redirect_cont {
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    background-color: var(--color-mbl-card-bg-2);
  }
  .course_detail_container_main_sub_details_sub {
    flex: 1;
    padding: 1rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    border-radius: 8px;
    background-color: var(--color-mbl-card-bg-2);
    -ms-overflow-style: none;
  }
}

@media screen and (max-width: 870px) {
  .course_detail_container_main_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    border: 0px solid var(--color-input-border);
    border-radius: 0px;
    padding: 1.3rem 1.3rem;
    overflow: auto;
  }
  .course_detail_container_main_sub_reg_det {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -ms-overflow-style: none;
    /* background-color: #0030dc; */
  }
}

@media screen and (max-width: 592px) {
  .course_detail_container_main {
    max-width: 1435px;
    min-height: calc(100dvh - 3.75rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.8rem;
    margin: auto;
    /* background-color: red; */
  }
  .course_detail_container_main_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    border: 0px solid var(--color-input-border);
    border-radius: 0px;
    padding: 0.1rem;
    overflow: auto;
  }
  .form_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    /* background-color: antiquewhite; */
  }

  .form_column .form_group {
    width: 100%;
  }

  .redirect_cont span {
    width: 90%;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 0.03rem;
    color: var(--color-mid-white);
  }
}

@media screen and (max-width: 500px) {
  .you_pay_cont_sub {
    width: 100%;
    height: fit-content;
    margin-top: 0.6rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-self: center;
    flex-direction: column;
    border: 1px solid var(--color-btn);
    gap: 1rem;
    border-radius: 8px;
  }
  .you_pay_cont_sub_price_cont {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    gap: 0.8rem;
    margin-left: 0rem;
  }
  .redirect_cont img {
    width: 8rem;
    height: 8rem;
  }
}

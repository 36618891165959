.dashboard_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
  margin: auto;
  gap: 3rem;
  /* background-color: red; */
}

.cards_main_cont {
  width: 100%;
  height: fit-content;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-top: 1rem;
  /* background-color: yellow; */
}

.cards_heading_cont {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-mid-white);
}

.cards_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  /* background-color: aqua; */
}

.cards {
  height: 11rem;
  min-width: 26rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--color-border);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #111111;
}

.card_heading_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card_heading_text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-white);
}

.card_gauge_cont {
  width: 4.8rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cards_visual_cont {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2rem;
  /* background-color: antiquewhite; */
}

.donut_cont {
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line_main_cont {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* background-color: aquamarine; */
}

.line_cont {
  width: 95%;
  height: 45%;
  display: flex;
  align-items: flex-end;
  /* background-color: red; */
}

.line_out {
  width: 100%;
  height: 35%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  background-color: #1e1f1f;
}

.line_in_one {
  width: 20%;
  height: 90%;
  border-radius: 10px;
  position: relative;
  background-color: #b22222;
}

.line_in_one_text {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 500;
  top: -50px;
  left: 1;
  color: #b22222;
}

.line_in_one_num {
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  top: -30px;
  left: 1;
  color: #b22222;
}

.line_in_two {
  width: 20%;
  height: 90%;
  border-radius: 10px;
  position: relative;
  background-color: #016646;
}

.line_in_two_text {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 500;
  top: -50px;
  left: 1;
  color: #016646;
}

.line_in_two_num {
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  top: -30px;
  left: 1;
  color: #016646;
}

.description_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.4rem;
  /* background-color: #016646; */
}

.consider_stocks_cont {
  width: 56.7rem;
  height: fit-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: #111111;
}

.consider_stocks_cont_sub {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  border-radius: 10px;
  /* background-color: red; */
}

.example_cont {
  width: 30rem;
  height: 11rem;
  border-radius: 8px;
  position: relative;
}

.example_cont_img {
  width: 100%;
  height: 100%;
  opacity: 0.45;
  background-image: url("../../../assets/images/cat1.jpg");
  background-size: cover;
}

.text_rr_cont {
  position: absolute;
  top: 9px;
  left: 9px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  /* background-color: #b22222; */
}

.example_text {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Play", sans-serif;
  color: var(--color-mid-white);
}

.rr_cont {
  width: 20rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
  /* background-color: yellow; */
}

.rr {
  width: 50%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1.5px solid var(--color-1);
  border-radius: 8px;
}

.stocks_to_watch {
  width: 23rem;
  height: 11rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* background-color: yellow; */
}

.indicator_cont {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* background-color: #111111; */
}

.indicator_cont_sub {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  /* background-color: blue; */
}

.circle_outer {
  width: 15px;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #777;
  border-radius: 50px;
}

.circle_inner {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #b22222;
}

.indicator_text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-mid-white);
}

.marquee_cont {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  /* background-color: aqua; */
}

.marquee_cont_sub {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.marquee_card {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 2rem;
  border-radius: 6px;
  background-color: var(--color-2);
}

.trading_ticker_cont {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  /* background-color: aqua; */
}

.free_analysis_cont {
  min-width: 26rem;
  height: 19rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: #111111;
}

.free_analysis_heading_text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-mid-white);
}

.chart_status_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  /* background-color: #016646; */
}

.chart {
  width: 56.7rem;
  height: fit-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: #111111;
}

.chart_heading_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chart_name_card {
  width: fit-content;
  height: fit-content;
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
  border-radius: 5px;
  background-color: var(--color-btn);
}

.status {
  width: 26rem;
  height: 19rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: #111111;
}

@media screen and (max-width: 1395px) {
  .consider_stocks_cont_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    border-radius: 10px;
    /* background-color: red; */
  }
  .example_cont {
    width: 25rem;
    height: 11rem;
    border-radius: 8px;
    position: relative;
  }
}

@media screen and (max-width: 1340px) {
  .cards {
    height: 11rem;
    min-width: 23rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #111111;
  }
}

@media screen and (max-width: 1320px) {
  .example_cont {
    width: 23rem;
    height: 11rem;
    border-radius: 8px;
    position: relative;
  }
}

@media screen and (max-width: 1296px) {
  .example_cont {
    width: 100%;
    height: 11rem;
    border-radius: 8px;
    position: relative;
  }
  .text_rr_cont {
    position: absolute;
    top: 9px;
    left: 9px;
    right: 9px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* background-color: #b22222; */
  }

  .rr_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    /* background-color: yellow; */
  }
  .rr {
    width: 50%;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--color-1);
    border-radius: 8px;
  }
  .free_analysis_cont {
    min-width: 26rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 1200px) {
  .stocks_to_watch {
    width: 20rem;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 1194px) {
  .cards {
    height: 11rem;
    min-width: 20rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #111111;
  }
}

@media screen and (max-width: 1156px) {
  .stocks_to_watch {
    width: 17rem;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 1111px) {
  .free_analysis_cont {
    min-width: 23rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 1061px) {
  .dashboard_container_main {
    max-width: 1435px;
    min-height: calc(100dvh - 3.75rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    margin: auto;
    gap: 2rem;
    /* background-color: red; */
  }
  .description_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    /* background-color: #016646; */
  }
  .consider_stocks_cont {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .rr_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    /* background-color: yellow; */
  }
  .rr {
    width: 50%;
    height: 3rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--color-1);
    border-radius: 8px;
  }
  .stocks_to_watch {
    width: 23rem;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }

  .free_nifty_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  .free_analysis_cont {
    min-width: 30rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 28rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .chart {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 1046px) {
  .cards {
    height: 11rem;
    min-width: 17rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #111111;
  }

  .donut_cont {
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .line_out {
    width: 100%;
    height: 30%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50px;
    background-color: #1e1f1f;
  }
  .line_in_one {
    width: 20%;
    height: 80%;
    border-radius: 10px;
    position: relative;
    background-color: #b22222;
  }
  .line_in_two {
    width: 20%;
    height: 80%;
    border-radius: 10px;
    position: relative;
    background-color: #016646;
  }
  .line_in_one_text {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    top: -47px;
    left: 1;
    color: #b22222;
  }
  .line_in_two_text {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    top: -47px;
    right: 1px;
    color: #016646;
  }

  .line_in_one_num {
    position: absolute;
    font-size: 0.9rem;
    font-weight: 500;
    top: -27px;
    left: 1;
    color: #b22222;
  }
  .line_in_two_num {
    position: absolute;
    font-size: 0.9rem;
    font-weight: 500;
    top: -27px;
    right: 1px;
    color: #016646;
  }
}

@media screen and (max-width: 996px) {
  .free_analysis_cont {
    min-width: 28rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 26rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 939px) {
  .free_analysis_cont {
    min-width: 26rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 24rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 903px) {
  .cards {
    height: 11rem;
    min-width: 15rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #111111;
  }
  .line_in_one_text {
    display: none;
  }
  .line_in_two_text {
    display: none;
  }
}

@media screen and (max-width: 862px) {
  .free_analysis_cont {
    min-width: 24rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 24rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 834px) {
  .free_analysis_cont {
    min-width: 24rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 22rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 806px) {
  .cards_main_cont {
    width: 100%;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 1.1rem;
    margin-top: 0.8rem;
    /* background-color: yellow; */
  }

  .cards_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }
  .cards_heading_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0 3px;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--color-mid-white);
  }
  .donut_cont {
    width: 5.5rem;
    height: 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .line_out {
    width: 100%;
    height: 30%;
    padding: 2px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 50px;
    background-color: #1e1f1f;
  }
  .line_in_one_text {
    display: block;
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    top: -47px;
    left: 1;
    color: #b22222;
  }
  .line_in_two_text {
    display: block;
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    top: -47px;
    right: 1px;
    color: #016646;
  }
}

@media screen and (max-width: 787px) {
  .free_analysis_cont {
    min-width: 22rem;
    height: 19.1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 22rem;
    height: 19rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 766px) {
  .free_nifty_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
  }
  .free_analysis_cont {
    min-width: 100%;
    height: 20rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
  .status {
    width: 100%;
    height: 25rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    background-color: #111111;
  }
}

@media screen and (max-width: 750px) {
  .stocks_to_watch {
    width: 20rem;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 702px) {
  .stocks_to_watch {
    width: 17rem;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 659px) {
  .example_text {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "Play", sans-serif;
    color: var(--color-mid-white);
  }
  .rr_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    /* background-color: yellow; */
  }
  .rr {
    width: 70%;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--color-1);
    border-radius: 8px;
  }
}

@media screen and (max-width: 606px) {
  .consider_stocks_cont_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    border-radius: 10px;
    /* background-color: red; */
  }
  .example_text {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Play", sans-serif;
    color: var(--color-mid-white);
  }
  .rr_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    /* background-color: yellow; */
  }
  .rr {
    width: 70%;
    height: 3rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--color-1);
    border-radius: 8px;
  }
  .stocks_to_watch {
    width: 100%;
    height: 11rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 441px) {
  .rr {
    width: 70%;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1.5px solid var(--color-1);
    border-radius: 8px;
  }
}

@media screen and (max-width: 350px) {
  .cards_visual_cont {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    /* background-color: antiquewhite; */
  }
  .donut_cont {
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .example_text {
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "Play", sans-serif;
    color: var(--color-mid-white);
  }
  .rr_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 312px) {
  .line_in_one_text {
    display: none;
  }
  .line_in_two_text {
    display: none;
  }
}

@media screen and (max-width: 287px) {
  .cards {
    height: 10.5rem;
    min-width: 12rem;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #111111;
  }
  .donut_cont {
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.login_container_main {
  min-height: calc(100dvh - 3.75rem);
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.login_container_sub {
  width: 1248px;
  padding: 0.5rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  /* background-color: black; */
}

.login_container {
  width: 50%;
  margin: auto;
  padding: 1.5rem;
  background-color: var(--color-main-bg);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading_text {
  width: 50%;
  align-self: center;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: var(--size-6);
  font-weight: 500;
  text-align: left;
  color: var(--color-white);
}

.form_group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: var(--size-4);
  color: var(--color-white);
}

.input_field {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color-white);
  background-color: transparent;
}

.input_field:focus {
  outline-color: var(--color-btn);
  outline-width: 1.5px;
  outline-style: solid;
}

.submit_button {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.modal_div {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.already_acc_text {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--color-grey-600);
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.already_acc_text a {
  font-weight: 500;
  text-decoration: none;
  color: var(--color-btn);
}

.forgot_pw_text {
  width: 49%;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-grey-600);
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 924px) {
  .login_container_main {
    min-height: calc(100dvh - 4rem);
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--color-main-bg-2);
  }
}

@media screen and (max-width: 835px) {
  .login_container {
    width: 80%;
    margin: auto;
    padding: 1.5rem 1rem;
    background-color: var(--color-main-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .forgot_pw_text {
    width: 79%;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--color-grey-600);
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .heading_text {
    width: 80%;
    align-self: center;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: var(--size-5);
    font-weight: 500;
    text-align: left;
    color: var(--color-white);
  }
}

@media screen and (max-width: 560px) {
  .login_container {
    width: 90%;
    margin: auto;
    padding: 1.5rem 1rem;
    background-color: var(--color-main-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .forgot_pw_text {
    width: 89%;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--color-grey-600);
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .heading_text {
    width: 90%;
    align-self: center;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: var(--size-5);
    font-weight: 500;
    text-align: left;
    color: var(--color-white);
  }
}

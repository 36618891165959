.course_detail_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: auto;
  /* background-color: red; */
}

.course_detail_container_main_sub {
  width: 95%;
  height: 35rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  border: 1px solid var(--color-input-border);
  border-radius: 10px;
  overflow: auto;
}

.course_detail_container_main_sub_img {
  width: 25%;
  height: 100%;
  position: relative;
}

.course_detail_container_main_sub_img_sub_one {
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/images/course_one.jpg");
  background-size: cover;
  opacity: 0.4;
}

.course_detail_container_main_sub_img_sub_two {
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/images/course_two.jpg");
  background-size: cover;
  opacity: 0.3;
}

.course_detail_container_main_sub_img_sub_three {
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/images/course_three.jpg");
  background-size: cover;
  background-position: right;
  opacity: 0.3;
}

.course_detail_container_main_sub_img > span {
  width: 100%;
  position: absolute;
  top: 42%;
  font-size: 1.9rem;
  font-weight: 700;
  color: var(--color-white);
  text-align: center;
}

.course_detail_container_main_sub_details {
  flex: 1;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  -ms-overflow-style: none;
}

.course_detail_container_main_sub_details::-webkit-scrollbar {
  width: 0;
}

.course_detail_container_main_sub_details_sub {
  flex: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  -ms-overflow-style: none;
}

.course_detail_container_main_sub_details_sub::-webkit-scrollbar {
  width: 0;
}

.tab_cont {
  position: sticky;
  top: 0;
  z-index: 500;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 0.5rem;
  background-color: var(--color-main-bg-2);
}

.tab {
  padding: 0.25rem 0.9rem;
  border: 0.8px solid var(--color-btn);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  margin-bottom: 0.2rem;
}

.tab span {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-white);
}

.course_detail_header {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-3);
}

.small_cards_cont {
  width: 100%;
  height: fit-content;
  padding: 0.5rem;
  padding-left: 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.small_cards {
  width: fit-content;
  height: fit-content;
  padding: 0.2rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-border);
}

.small_cards > img {
  width: 2rem;
  height: 2rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
}

.small_cards > span {
  font-size: 0.7rem;
  font-weight: 500;
  color: #fff;
}

.about_course_cont {
  width: 100%;
  height: fit-content;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  /* background-color: aquamarine; */
}

.about_course_cont_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-3);
}

.about_course_cont_content {
  width: 100%;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  font-size: 0.9rem;
  color: var(--color-mid-white);
  overflow: auto;
}

.what_else_cont {
  width: 100%;
  height: fit-content;
  margin-top: 0.2rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: var(--color-btn-text);
}

.what_else_cont_sub {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 0.5rem;
}

.what_else {
  width: 15rem;
  height: fit-content;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.what_else_icon {
  width: 3rem;
  height: 3rem;
}

.what_else_text {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-right: 1rem;
}

.what_else_head {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-white);
}

.what_else_description {
  font-size: 0.7rem;
  font-weight: 500;
  color: var(--color-dark-white);
}

.buy_now_cont {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0.5rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 8px;
  margin-left: auto;
  background-color: var(--color-btn-text);
}

.price_cont {
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.price_cont > span {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-white);
}

.strike_price_cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.strike_price_text {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-white);
  text-decoration: line-through;
}

.offer_price_text {
  font-size: 0.7rem;
  font-weight: 400;
  color: yellow;
  margin-top: 0.2rem;
}

.buy_now_btn {
  color: var(--color-white) !important;
  background-color: #0030dc !important;
  position: relative;
  overflow: hidden;
}

.buy_now_btn::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    transform: rotate(160deg) translate(-300%, -300%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(160deg) translate(300%, 300%);
    opacity: 0;
  }
}

.you_pay_cont {
  width: 100%;
  height: fit-content;
  padding: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.you_pay_cont_sub {
  width: 90%;
  height: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--color-btn);
  border-radius: 8px;
}

.you_pay_cont_sub > span {
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-mid-white);
}

.you_pay_cont_sub_price_cont {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.you_pay_price_strike_text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-mid-white);
  text-decoration: line-through;
}

.you_pay_price_text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-white);
}

.content_main {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  /* background-color: red; */
}

.main_heading_text {
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-3);
  margin-bottom: 1rem;
}

.side_heading_text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-mid-white);
}

.contents {
  height: 65px;
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.8rem;
  margin-bottom: 1.3rem;
  background-color: var(--color-transparent-bg);
  border-radius: 10px;
  /* background-color: yellow; */
}

.contents_left {
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.contents_left img {
  width: 3rem;
  height: 3rem;
}

.contents_center {
  flex: 1;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  /* background-color: cyan; */
}

.contents_center_text_1 {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contents_center_text_2 {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--color-btn);
}

.contents_right {
  width: 4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.play_btn {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #555;
  border-radius: 50%;
  cursor: pointer;
}

.play_btn_img {
  width: 30px;
  height: 30px;
}

.modal {
  width: 50%;
  background-color: red;
}

.overlay {
  position: "fixed";
  z-index: 1020;
  margin: "auto";
  width: "100vw";
  height: "100vh";
  background-color: "rgba(30, 30, 30, 0.7)";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border-radius: "8px";
}

@media screen and (max-width: 1178px) {
  .what_else {
    width: 12rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 976px) {
  .what_else {
    width: 10rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 950px) {
  .contents {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.8rem;
    margin-bottom: 1.3rem;
    background-color: var(--color-transparent-bg);
    border-radius: 10px;
    /* background-color: yellow; */
  }
}

@media screen and (max-width: 924px) {
  .tab_cont {
    position: sticky;
    top: 0;
    z-index: 500;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
    background-color: var(--color-main-bg);
  }
  .what_else_cont {
    width: 100%;
    height: fit-content;
    margin-top: 0.2rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background-color: var(--color-transparent-bg);
  }
  .buy_now_cont {
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0.5rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-transparent-bg-2);
  }
}

@media screen and (max-width: 842px) {
  .what_else {
    width: 14rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 786px) {
  .what_else {
    width: 12rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 780px) {
  .course_detail_container_main_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    border: none;
    border-radius: 0px;
    overflow: auto;
    position: relative;
  }
  .course_detail_container_main_sub_img {
    width: 100%;
    height: 13rem;
    border-radius: 10px;
    overflow: hidden;
  }

  .content_cont {
    width: 100%;
    height: 20rem;
    overflow: auto;
  }

  .buy_now_cont {
    position: sticky;
    width: 50%;
    bottom: 0;
    z-index: 1000;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-btn-text);
  }
}

@media screen and (max-width: 780px) {
  .what_else {
    width: 10rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .what_else {
    width: 13rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .buy_now_cont {
    position: sticky;
    width: 60%;
    bottom: 0;
    z-index: 1000;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-btn-text);
  }
}

@media screen and (max-width: 515px) {
  .about_course_cont_content {
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-size: 0.9rem;
    color: var(--color-mid-white);
    overflow: auto;
  }
  .buy_now_cont {
    position: sticky;
    width: 80%;
    bottom: 0;
    z-index: 1000;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-btn-text);
  }
}

@media screen and (max-width: 504px) {
  .what_else {
    width: 11rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .contents_left {
    width: 2.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }

  .contents_right {
    width: 2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */
  }
}

@media screen and (max-width: 440px) {
  .what_else {
    width: 9rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 390px) {
  .buy_now_cont {
    position: sticky;
    width: 90%;
    bottom: 0;
    z-index: 1000;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-btn-text);
  }
  .what_else {
    width: 8rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .what_else_icon {
    width: 2rem;
    height: 2rem;
  }

  .what_else_text {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-right: 1rem;
  }

  .what_else_head {
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--color-white);
  }

  .what_else_description {
    font-size: 0.6rem;
    font-weight: 500;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 358px) {
  .what_else {
    width: 7rem;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .buy_now_cont {
    position: sticky;
    width: 98%;
    bottom: 0;
    z-index: 1000;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border-radius: 8px;
    margin-left: auto;
    background-color: var(--color-btn-text);
  }
}

@media screen and (max-width: 312px) {
  .what_else {
    width: 100%;
    height: fit-content;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.secret_cont {
  width: 100%;
  height: fit-content;
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.secret_cont_sub {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}

.secret_heading_cont {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 1rem;
  margin-bottom: 30px;
}

.secret_heading_cont > h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0px;
}

.secret_heading_cont p {
  width: 75%;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
  text-align: center;
}

.secret_img_cont {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secret_img_cont img {
  width: 100%;
}
.secret_img_cont div {
  position: absolute;
  bottom: 12%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.secret_img_cont div span {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
  text-align: center;
}

.secret_diff_cont {
  width: 100%;
  height: fit-content;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
}

.secret_diff_sub {
  width: 22rem;
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  box-shadow: 0px 0px 40px 0px rgba(62, 62, 62, 0.1);
  border-radius: 8px;
  background-color: var(--color-white);
}

.secret_diff_sub > h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0px;
}

.secret_diff_sub ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding-left: 0px;
  margin-top: 20px;
}

.secret_diff_sub ul li {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.secret_diff_sub ul li img {
  width: 1.2rem;
  height: 1.2rem;
}

.secret_diff_sub ul li span {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-grey-800);
  margin-bottom: 0px;
  text-align: center;
}

.carousel_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.carousel_cont h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 27px;
}

.one_btn_cont {
  width: 25rem;
  height: fit-content;
  padding: 0px 30px;
  margin: 0 auto;
  margin-top: 40px;
}

.one_btn_cont button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Poppins", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #0067de;
  border-radius: 15px 15px 15px 15px;
  border: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 22px 10px 22px 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.one_btn_cont button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: all 0.8s ease;
  animation: shine 1.5s infinite;
}

.one_btn_cont button div {
  position: absolute;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #fff;
}

@keyframes shine {
  0% {
    transform: rotate(160deg) translate(-300%, -300%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(160deg) translate(300%, 300%);
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  .secret_heading_cont {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1110px) {
  .secret_img_cont div {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .secret_cont_sub {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
  }
  .secret_heading_cont {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 895px) {
  .secret_img_cont div {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .secret_img_cont div span {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
  }
}

@media screen and (max-width: 822px) {
  .secret_heading_cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
  .secret_diff_cont {
    width: 100%;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .secret_heading_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 0.7rem;
    margin-bottom: 20px;
  }
  .secret_heading_cont > h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .secret_heading_cont p {
    font-size: 15px;
  }
}

@media screen and (max-width: 730px) {
  .secret_img_cont div {
    position: static;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1rem;
  }
  .secret_img_cont div span {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
  }
  .secret_diff_cont {
    width: 100%;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    margin-top: 37px;
  }
  .secret_diff_sub {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    box-shadow: 0px 0px 40px 0px rgba(62, 62, 62, 0.1);
    border-radius: 8px;
    background-color: var(--color-white);
  }
}

@media screen and (max-width: 676px) {
  .secret_img_cont div {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .secret_img_cont div span {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
    line-height: 18px;
  }
}

@media screen and (max-width: 621px) {
  .secret_heading_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 0.5rem;
    margin-bottom: 27px;
  }
  .secret_heading_cont > h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .secret_heading_cont p {
    width: 85%;
    font-size: 15px;
  }

  .secret_img_cont div {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .secret_img_cont div span {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
    line-height: 18px;
  }
  .one_btn_cont {
    width: 100%;
    height: fit-content;
    padding: 0px;
    margin-top: 32px;
  }
}

@media screen and (max-width: 511px) {
  .secret_img_cont div {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .secret_img_cont div span {
    font-family: "Poppins", Sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
    text-align: center;
    line-height: 18px;
  }
}

@media screen and (max-width: 500px) {
  .secret_heading_cont p {
    width: 90%;
    font-size: 15px;
  }
}

@media screen and (max-width: 470px) {
  .secret_heading_cont p {
    width: 100%;
    font-size: 15px;
  }
}

@media screen and (max-width: 413px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 80px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .one_btn_cont button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: "Poppins", Sans-serif;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #0067de;
    border-radius: 15px 15px 15px 15px;
    border: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 22px 10px 22px 40px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }
}

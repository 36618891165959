.course_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  margin: auto;
  gap: 5rem;
  /* background-color: red; */
}

.course_text {
  width: 95%;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-white);
  text-align: left;
  margin-top: 2rem;
}

.course_container_sub {
  width: 95%;
  padding: 1rem;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
  /* background-color: blue; */
}

.course_card {
  width: 30rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--color-border);
  background-color: var(--color-main-bg);
}

.card_img_cont {
  width: 100%;
  height: 10rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--color-main-bg);
}

.card_img_cont img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card_text_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  gap: 1rem;
  margin-top: 0.5rem;
}

.card_text_cont span:nth-child(1) {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-white);
  text-align: left;
}

.card_text_cont span:nth-child(2) {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-mid-white);
  text-align: left;
}

.course_card_float {
  position: absolute;
  bottom: -9%;
  left: 9.5%;
  height: 3.5rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  background-color: var(--color-main-bg);
}

.course_card_float_sub {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course_card_float_sub span:nth-child(1) {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-btn);
}

.course_card_float_sub span:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--color-mid-white);
}

@media screen and (max-width: 1130px) {
  .course_card {
    width: 25rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 960px) {
  .course_container_sub {
    width: 95%;
    padding: 1rem;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    /* background-color: blue; */
  }
  .course_card {
    width: 23rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 939px) {
  .course_container_main {
    max-width: 1435px;
    min-height: calc(100dvh - 3.75rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    margin: auto;
    gap: 3rem;
    padding-bottom: 5rem;
    /* background-color: red; */
  }
  .course_container_sub {
    width: 95%;
    padding: 1rem;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6rem;
    /* background-color: blue; */
  }
  .course_card {
    width: 80%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 600px) {
  .course_card {
    width: 90%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: var(--color-main-bg);
  }
  .course_card_float {
    position: absolute;
    bottom: -9%;
    left: 9.5%;
    height: 3.5rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 0.5rem 0.8rem;
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 520px) {
  .course_container_sub {
    width: 95%;
    padding: 1rem;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5rem;
    /* background-color: blue; */
  }
  .course_card {
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--color-border);
    background-color: var(--color-main-bg);
  }
  .course_card_float_sub span:nth-child(2) {
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--color-mid-white);
  }
}

@media screen and (max-width: 433px) {
  .course_container_main {
    max-width: 1435px;
    min-height: calc(100dvh - 3.75rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
    margin: auto;
    gap: 2rem;
    padding-bottom: 5rem;
    /* background-color: red; */
  }
  .course_card_float {
    position: absolute;
    bottom: -9%;
    left: 5.6%;
    height: 3.5rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 0.5rem 0.8rem;
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 370px) {
  .course_card_float button {
    padding: 0.2rem 0.8rem !important;
  }
}

@media screen and (max-width: 325px) {
  .course_card_float_sub {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  }
  .course_card_float_sub span:nth-child(1) {
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--color-btn);
  }

  .course_card_float_sub span:nth-child(2) {
    font-size: 0.6rem;
    font-weight: 700;
    color: var(--color-mid-white);
  }
}

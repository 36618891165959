.carousel_cont {
  width: 100%;
  height: fit-content;
  border-radius: 16px;
  /* border: 1px solid #000; */
  box-shadow: 0px 0px 40px 0px rgba(62, 62, 62, 0.1);
  background-color: #fff;
}
.carousel {
  width: 100%;
  height: fit-content;
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.carousel_pic_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
  /* background-color: black; */
}

.carousel_pic {
  width: 35%;
  height: 13rem;
  border: 1px solid #0067de;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.carousel_pic img {
  width: 100%;
  height: 100%;
}

.carousel_pic_btn {
  position: absolute;
  width: fit-content;
  height: 1rem;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px 8px;
  border-radius: 5px;
  background-color: #fff;
}

.carousel_pic_btn span {
  font-family: "Poppins", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #000;
  text-align: center;
  white-space: nowrap;
  margin: 0;
}

.carousel_details_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  /* background-color: yellow; */
}

.stock_name_cont {
  width: fit-content;
  /* height: 100%; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  /* background-color: black; */
}

.stock_name_cont h2 {
  color: #00ff0a;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0px;
  white-space: nowrap;
}

.stock_name_cont span {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

.carousel_details_cont_sub {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
}

.detail_cont {
  width: 8rem;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #0067de;
  box-shadow: 0px 0px 40px 0px rgba(45, 45, 45, 0.1);
  background-color: #fff;
}

.detail_cont h2 {
  color: #0067de;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0px;
  white-space: nowrap;
}

.detail_cont span {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  margin-bottom: 0px;
  color: #000;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 1241px) {
  .carousel_pic {
    width: 50%;
    height: 13rem;
    border: 1px solid #0067de;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media screen and (max-width: 831px) {
  .carousel_pic {
    width: 50%;
    height: 11rem;
    border: 1px solid #0067de;
    border-radius: 10px;
    overflow: hidden;
  }
}

@media screen and (max-width: 783px) {
  .stock_name_cont {
    width: fit-content;
    /* height: 100%; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 12px;
    /* background-color: black; */
  }

  .stock_name_cont h2 {
    color: #00ff0a;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4em;
    text-align: center;
    margin-bottom: 0px;
    white-space: nowrap;
  }

  .stock_name_cont span {
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
  }

  .carousel_details_cont_sub {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }

  .detail_cont {
    width: 8rem;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #0067de;
    box-shadow: 0px 0px 40px 0px rgba(45, 45, 45, 0.1);
    background-color: #fff;
  }

  .detail_cont h2 {
    color: #0067de;
    font-family: "Poppins", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4em;
    text-align: center;
    margin-bottom: 0px;
    white-space: nowrap;
  }

  .detail_cont span {
    font-family: "Poppins", Sans-serif;
    font-size: 13px;
    margin-bottom: 0px;
    color: #000;
    text-align: center;
    white-space: nowrap;
  }
}

@media screen and (max-width: 645px) {
  .carousel {
    width: 100%;
    height: fit-content;
    padding: 10px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    border-radius: 16px;
    /* background-color: aqua; */
  }
  .carousel_pic_cont {
    width: 70%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    /* background-color: black; */
  }

  .carousel_pic {
    width: 100%;
    height: 13rem;
    border: 1px solid #0067de;
    border-radius: 10px;
    overflow: hidden;
  }
  .carousel_details_cont {
    flex: 1;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;
    /* background-color: yellow; */
  }
  .stock_name_cont {
    width: 100%;
    /* height: 100%; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 12px;
    /* background-color: black; */
  }
  .carousel_details_cont_sub {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }
  .detail_cont {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #0067de;
    box-shadow: 0px 0px 40px 0px rgba(45, 45, 45, 0.1);
    background-color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .carousel {
    width: 100%;
    height: fit-content;
    padding: 15px;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 16px;
    /* background-color: aqua; */
  }
  .carousel_pic_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    /* background-color: black; */
  }

  .carousel_pic {
    width: 100%;
    height: 10rem;
    border: 1px solid #0067de;
    border-radius: 10px;
    overflow: hidden;
  }
  .carousel_details_cont {
    flex: 1;
    height: fit-content;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    /* background-color: yellow; */
  }
  .stock_name_cont {
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    border-radius: 12px;
    background-color: black;
    display: none;
  }
  .carousel_details_cont_sub {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }
  .detail_cont {
    width: 8rem;
    height: fit-content;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #0067de;
    box-shadow: 0px 0px 40px 0px rgba(45, 45, 45, 0.1);
    background-color: #fff;
  }
}

.course_main_container {
  max-width: 1435px;
  padding: 0.5rem 0.5rem;
  height: calc(100dvh - 4rem);
  display: flex;
  gap: 0.5rem;
  margin: auto;
  background-color: var(--color-main-bg-2);
}

.video_desc_cont {
  height: 100%;
  flex: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: blue; */
  border-right: 1px solid var(--color-border);
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.video_desc_cont::-webkit-scrollbar {
  display: none;
}

.video_player_cont {
  width: 100%;
  min-height: 25rem;
  /* background-color: green; */
}

.desc_cont {
  width: 100%;
  height: 20rem;
  padding: 0.5rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid var(--color-border);
  /* background-color: yellow; */
}

.desc_card {
  width: fit-content;
  height: fit-content;
  padding: 0.2rem 0.8rem;
  color: #000;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: var(--color-btn);
}

.desc_heading {
  width: 100%;
  height: fit-content;
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
  white-space: wrap;
  text-align: left;
}

.desc_description {
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
  font-weight: 400;
  gap: 1rem;
  color: var(--color-mid-white);
  white-space: wrap;
  text-align: left;
  /* background-color: red; */
  overflow: scroll;
}

.desc_description::-webkit-scrollbar {
  width: 2px;
}

.category_cont {
  width: 25%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-left: 1px solid var(--color-grey-700); */
  /* background-color: red; */
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category_cont::-webkit-scrollbar {
  display: none;
}

.sub_item_cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.sub_item_cont span:nth-child(1) {
  font-size: 0.88rem;
  font-weight: 400;
  color: var(--color-white);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sub_item_cont span:nth-child(2) {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-dark-white);
}

@media screen and (max-width: 900px) {
  .course_main_container {
    max-width: 1435px;
    padding: 0.5rem 0.5rem;
    height: calc(100dvh - 3.75rem);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: auto;
    background-color: var(--color-main-bg-2);
  }
  .video_desc_cont {
    height: 100%;
    flex: 1;
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-right: none;
    /* background-color: blue; */
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .video_desc_cont::-webkit-scrollbar {
    display: none;
  }
  .video_player_cont {
    width: 100%;
    min-height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: green; */
  }

  .desc_cont {
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: none;
    /* background-color: yellow; */
  }
  .desc_description {
    height: 15rem;
    /* background-color: red; */
    overflow: scroll;
    scrollbar-width: 2px;
  }
  .category_cont {
    width: 100%;
    height: 22rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: none;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .category_cont::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .video_player_cont {
    width: 100%;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: green; */
  }
}

@media screen and (max-width: 460px) {
  .video_player_cont {
    width: 100%;
    min-height: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: green; */
  }
}

@media screen and (max-width: 380px) {
  .video_player_cont {
    width: 100%;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: green; */
  }
}

.profile_container_main {
  max-width: 1435px;
  min-height: calc(100dvh - 3.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: auto;
  /* background-color: red; */
}

.profile_container_main_sub {
  width: 95%;
  height: 35rem;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  border: 1px solid var(--color-input-border);
  border-radius: 10px;
  overflow: auto;
}

.profile_container_main_sub_img {
  width: 20rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-right: 1px solid var(--color-border);
  /* background-color: aqua; */
}

.profile_circle {
  width: 300px;
  height: 300px;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  background-color: var(--color-transparent-bg);
  cursor: pointer;
}

.profile_container_main_sub_details {
  flex: 1;
  height: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* background-color: bisque; */
}

.input_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.input_reset_cont {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.input_reset_cont_sub {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.input_reset_cont_sub_btns {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
}

.label_input {
  width: 45%;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: var(--size-4);
  color: var(--color-dark-white);
}

.input_field {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color-mid-white);
  background-color: transparent;
}

.reset_password_btn {
  align-self: flex-start;
  padding: 0.25rem 0.6rem;
}

.logout_btn_cont {
  width: 100%;
  margin-top: 7%;
  height: fit-content;
}

.logout_btn {
  align-self: flex-start;
  padding: 0.25rem 0.6rem;
  background-color: orange;
}

.logout_btn:hover {
  align-self: flex-start;
  padding: 0.25rem 0.6rem;
  background-color: rgb(243, 179, 61) !important;
}

.pw_text_indicator {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.pw_text_indicator span {
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--color-white);
}

@media screen and (max-width: 901px) {
  .profile_container_main_sub_img {
    width: 18rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    /* background-color: aqua; */
  }

  .profile_circle {
    width: 240px;
    height: 240px;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    background-color: var(--color-transparent-bg);
    cursor: pointer;
  }
}

@media screen and (max-width: 844px) {
  .profile_container_main_sub_img {
    width: 15rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    /* background-color: aqua; */
  }

  .profile_circle {
    width: 220px;
    height: 220px;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    background-color: var(--color-transparent-bg);
    cursor: pointer;
  }
}

@media screen and (max-width: 801px) {
  .profile_container_main_sub {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    border: 1px solid var(--color-input-border);
    border-radius: 10px;
  }
  .profile_container_main_sub_img {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    /* background-color: aqua; */
  }
  .profile_container_main_sub_details {
    flex: 1;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    /* background-color: bisque; */
  }
  .input_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }
  .label_input {
    width: 100%;
  }
  .input_reset_cont_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
}

@media screen and (max-width: 566px) {
  .input_cont {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.3rem;
  }
  .input_reset_cont_sub {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }
}

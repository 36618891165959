.header_outer {
  width: 100%;
  height: 3.75rem;
  background-color: var(--color-main-bg-2);
}

.nav {
  /* max-width: 1400px; */
  width: 100%;
  height: 100%;
  padding: 0px 1rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-700);
  background-color: var(--color-main-bg-2);
}

.nav_left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav_left ul {
  height: 100%;
  padding-left: 2rem;
  margin: 0;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.nav_left ul li {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
}

.nav_left ul li a {
  color: #fff;
  font-family: "Play", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  text-decoration: none;
}

.logo_container {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
}

.logo_container p {
  font-size: var(--size-6);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: var(--color-white);
}

.nav_right {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
}

.profile_circle {
  width: 2.3rem;
  height: 2.3rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: var(--color-transparent-bg);
  cursor: pointer;
}

.button_transparent {
  color: var(--color-white) !important;
  background-color: transparent !important;
}

.button_transparent:hover {
  color: var(--color-btn) !important;
  background-color: transparent !important;
}

.menu_btn_and_btn {
  width: auto;
  height: 100%;
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}

.menu_btn {
  width: auto;
  height: auto;
}

.menu_overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  top: 3.75rem;
  left: 0;
  width: 100%;
  height: calc(100% - 3.75rem);
  padding: 1rem;
  z-index: 10;
  background-color: rgba(0, 0, 2, 0.5);
}

.menu_container {
  width: 12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  padding: var(--size-4);
  z-index: 20;
  overflow: scroll;
  background-color: var(--color-main-bg);
}

.menu_option_container {
  height: auto;
  width: 100%;
}

.menu_option {
  color: #fff;
  padding: 1rem 0rem;
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
}

.menu_option span {
  color: #fff;
  font-family: "Play", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  text-decoration: none;
}

.menu_option:hover {
  background-color: var(--color-mid-white);
}

.menu_option_btn_container {
  width: 100%;
  height: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu_option_btn_default {
  padding: 0.3rem 0.8rem !important;
}

.menu_option_btn_transparent {
  color: var(--color-btn) !important;
  padding: 0.3rem 0.8rem !important;
  background-color: transparent !important;
}

.menu_option_btn_transparent:hover {
  color: var(--color-btn) !important;
  background-color: transparent !important;
}

.main_container {
  min-height: calc(100dvh - 3.75rem);
  margin-top: 0;
  background-color: var(--color-main-bg-2);
}

.whatsappIcon {
  position: fixed;
  bottom: 2rem;
  right: 2.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50px;
  background-color: var(--color-main-bg);
  border: 1px solid #25d366;
  z-index: 9999; /* Ensure it's above other elements */
}

.whatsappIcon img {
  width: 50px; /* Adjust size as needed */
}

@media screen and (max-width: 1350px) {
  .nav {
    max-width: 100%;
    height: 100%;
    padding: 0px 1rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey-700);
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 924px) {
  .main_container {
    min-height: calc(100dvh - 4rem);
    margin-top: 0;
    background-color: var(--color-main-bg);
  }
}

@media screen and (max-width: 410px) {
  .menu_btn_and_btn {
    width: auto;
    height: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

.secret_cont {
  width: 100%;
  height: fit-content;
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.secret_cont_sub {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
}

.secret_heading_cont {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 1rem;
  margin-bottom: 27px;
}

.secret_heading_cont > h2 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 0px;
}

.secret_heading_cont p {
  width: 75%;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  /* margin-bottom: 10px; */
  text-align: center;
}

.marquee_cont_sub {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.marquee_card {
  width: 24rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1rem;
  border: 1px solid #3f4345;
  border-radius: 16px;
  margin-right: 3rem;
  overflow: hidden;
  /* background-color: var(--color-mbl-card-bg-2); */
  background-color: #111;
}

.marquee_card_top {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  /* background-color: yellow; */
}

.profile_circle {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #252728;
  cursor: pointer;
}

.user_detail_cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.1rem;
  text-align: left;
  /* background-color: aquamarine; */
}

.name {
  color: #ecedee;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.id {
  color: var(--color-dark-white);
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 0.6rem;
  /* color: var(--color-btn); */
}

.insta_icon {
  margin-left: auto;
  align-items: center;
}

.marquee_card_bottom {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  overflow: scroll;
  /* background-color: bisque; */
}

.review_text {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.625;
  color: #ecedee;
}

@media screen and (max-width: 451px) {
  .marquee_card {
    width: 22rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1rem;
    border: 1px solid #3f4345;
    border-radius: 8px;
    margin-right: 3rem;
    overflow: hidden;
    background-color: var(--color-mbl-card-bg-2);
  }
  .review_main_heading {
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 4rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 65px;
    color: var(--color-dark-white);
  }
}

@media screen and (max-width: 425px) {
  .marquee_card {
    width: 20rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1rem;
    border: 1px solid #3f4345;
    border-radius: 8px;
    margin-right: 3rem;
    overflow: hidden;
    background-color: var(--color-mbl-card-bg-2);
  }
}

@media screen and (max-width: 380px) {
  .marquee_card {
    width: 18rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1rem;
    border: 1px solid #3f4345;
    border-radius: 8px;
    margin-right: 3rem;
    overflow: hidden;
    background-color: var(--color-mbl-card-bg-2);
  }
}

@media screen and (max-width: 1200px) {
  .secret_heading_cont {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .secret_cont_sub {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
  }
  .secret_heading_cont {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 822px) {
  .secret_heading_cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .secret_heading_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 0.7rem;
    margin-bottom: 20px;
  }
  .secret_heading_cont > h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .secret_heading_cont p {
    font-size: 15px;
  }
}

@media screen and (max-width: 621px) {
  .secret_heading_cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 0.5rem;
    margin-bottom: 27px;
  }
  .secret_heading_cont > h2 {
    color: #000000;
    font-family: "Poppins", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4em;
  }
  .secret_heading_cont p {
    width: 85%;
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
  .secret_heading_cont p {
    width: 90%;
    font-size: 15px;
  }
}

@media screen and (max-width: 470px) {
  .secret_heading_cont p {
    width: 100%;
    font-size: 15px;
    margin-bottom: 0px;
  }
}

.toggle_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.toggle_cont span {
  color: var(--color-white);
  font-size: 15px;
}

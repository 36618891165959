.privacy_page_container {
  max-width: 1435px;
  padding: 0.5rem 0.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 2rem;
  /* background-color: var(--color-mid-white); */
}

.top_image_container {
  width: 90%;
  height: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  margin-top: 2rem;
  border: 1px solid var(--color-main-bg-2);
  border-radius: 15px;
  background-image: url(../../../assets/images/privacy_policy_image1.svg);
  /* background-size: cover; */
}

.top_image_container span:nth-child(1) {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  color: var(--color-white);
}

.top_image_container span:nth-child(2) {
  font-size: 0.9rem;
  margin-top: 1rem;
  max-width: 60%;
  text-align: center;
  color: var(--color-white);
}

.bottom_content_container {
  width: 90%;
  height: fit-content;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}

.head_content_container {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.head_content_container span:nth-child(1) {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
  line-height: 38px;
  color: var(--color-white);
}

.head_content_container span:nth-child(2) {
  font-size: 0.9rem;
  text-align: left;
  color: var(--color-mid-white);
}

.head_date_container {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 3rem;
}

.head_date_container span:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  color: var(--color-white);
}

.head_date_container span:nth-child(2) {
  font-size: 0.8rem;
  text-align: left;
  margin-top: 3.5px;
  margin-left: 6px;
  color: var(--color-mid-white);
}

@media screen and (max-width: 550px) {
  .top_image_container span:nth-child(2) {
    font-size: 0.9rem;
    margin-top: 1rem;
    max-width: 80%;
    text-align: center;
    color: var(--color-white);
  }
}

@media screen and (max-width: 270px) {
  .top_image_container {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.5rem;
    margin-top: 2rem;
    border: 1px solid var(--color-main-bg-2);
    border-radius: 15px;
    background-image: url(../../../assets/images/privacy_policy_image1.svg);
  }
}

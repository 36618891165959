.service_container {
  padding: 3rem 0;
}

.service_container_sub {
  max-width: 1248px;
  height: fit-content;
  padding: 0rem 1.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: auto;
  /* background-color: blueviolet; */
}

.circle_cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle_outer {
  height: 14rem;
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 120px;
  background-color: #131c27;
}

.circle_inner {
  height: 10.5rem;
  width: 10.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  background-color: #10243e;
}

.circle_inner_img_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.circle_inner_img_text h2 {
  letter-spacing: -0.021rem;
  line-height: 1.4;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #369eff;
}

.overview_cont {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  /* background-color: aqua; */
}

.circle_line {
  height: auto;
  width: 1px;
  margin-top: -2.25rem;
}

.circle_line_in {
  position: relative;
  width: 100%;
  background-color: #0a427d;
}

.line_dot_stats {
  position: absolute;
  top: 5rem;
  right: -9.5px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50px;
  border: 2px solid #0a4481;
  position: absolute;
  background-color: #151718;
}

.line_dot_breakout {
  position: absolute;
  top: 19.7rem;
  right: -9.5px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50px;
  border: 2px solid #0a4481;
  background-color: #151718;
}

.line_dot_trailing {
  position: absolute;
  top: 31.15rem;
  right: -9.5px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50px;
  border: 2px solid #0a4481;
  background-color: #151718;
}

.line_dot_insights {
  position: absolute;
  top: 42.5rem;
  right: -9.5px;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50px;
  border: 2px solid #0a4481;
  background-color: #151718;
}

.overview_cont_left {
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: red; */
}

.overview_content_one {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
  position: relative;
}

.overview_content_one h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.017rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #ecedee;
  margin-bottom: 0.15rem;
}

.overview_content_one p {
  font-size: 1rem;
  font-weight: 400;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.625;
  letter-spacing: -0.011rem;
  color: #9ba1a6;
  margin-bottom: 1.5rem;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.overview_content_one p:hover {
  -webkit-line-clamp: unset;
}

.overview_content_one img {
  height: 32rem;
  margin-top: -1rem;
}

.overview_cont_right {
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: yellow; */
}

@media screen and (max-width: 924px) {
  .service_container {
    padding: 1rem 0;
  }
  .service_container_sub {
    max-width: 1248px;
    height: fit-content;
    padding: 0.5rem 0.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    /* background-color: blueviolet; */
  }
  .overview_cont {
    width: 90%;
    height: fit-content;
    padding: 0.3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1rem;
    /* background-color: aqua; */
  }
  .circle_line {
    height: auto;
    width: 1px;
    margin-top: 3rem;
  }
  .circle_line_in {
    width: 100%;
    background-color: #0a427d;
  }

  .overview_cont_left {
    flex: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: red; */
  }

  .overview_content_one h3 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.017rem;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #ecedee;
    margin-bottom: 0.15rem;
  }

  .overview_content_one p {
    font-size: 1rem;
    font-weight: 400;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.625;
    letter-spacing: -0.011rem;
    color: #9ba1a6;
    margin-bottom: 1.5rem;
    white-space: wrap;
    overflow: auto;
    text-overflow: unset;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }

  .line_dot_stats {
    position: absolute;
    top: 0.38rem;
    left: -2.57rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50px;
    border: 2px solid #0a4481;
    position: absolute;
    background-color: #151718;
  }

  .line_dot_breakout {
    position: absolute;
    top: 0.38rem;
    left: -2.57rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50px;
    border: 2px solid #0a4481;
    position: absolute;
    background-color: #151718;
  }

  .line_dot_trailing {
    position: absolute;
    top: 0.38rem;
    left: -2.57rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50px;
    border: 2px solid #0a4481;
    position: absolute;
    background-color: #151718;
  }

  .line_dot_insights {
    position: absolute;
    top: 0.38rem;
    left: -2.57rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50px;
    border: 2px solid #0a4481;
    position: absolute;
    background-color: #151718;
  }

  .overview_content_one img {
    height: 32rem;
    width: 20rem;
    margin: 0.5rem 0rem;
  }
}

@media screen and (max-width: 480px) {
  .overview_content_one img {
    height: 32rem;
    width: 20rem;
    margin: 0.5rem 0rem;
    margin-left: -2rem;
  }
}

@media screen and (max-width: 360px) {
  .overview_content_one img {
    height: 28rem;
    width: 17rem;
    margin: 0.5rem 0rem;
    margin-left: -2rem;
  }
}
